/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import {
  Home as HomeIcon,
  Users,
  DollarSign,
  BarChart2,
  Award,
  Box,
  BookOpen,
  BarChart,
  Sliders,
  UserCheck,
  User,
  Clipboard,
  Settings,
  FileText,
  Table,
  Tool,
} from "react-feather";

import { Speed } from "@material-ui/icons";

// Images

import dashboard from "../vendor/images/dashboard.png";
import templates from "../vendor/images/templates.png";
import reports from "../vendor/images/reports.png";
import humanResources from "../vendor/images/human-resource.png";
import trialLogs from "../vendor/images/trial-logs.png";
import userManagementImg from "../vendor/images/user-management.png";
import rotaryDieImg from "../vendor/images/rotary-die.png";
import manufacture from "../vendor/images/manufacture.jpg";
import folding from "../vendor/images/folding.jpg";
import customerService from "../vendor/images/customerService.jpg";
import quoteImage from "../vendor/images/quote.jpg";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ConfirmSignUp from "../pages/auth/ConfirmSignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import ConfirmResetPassword from "../pages/auth/ConfirmResetPassword";
import Page404 from "../pages/auth/Page404";
import Page403 from "../pages/auth/Page403";
import Page500 from "../pages/auth/Page500";
import Quote from "../pages/Reports/Quote";
import Efficiency from "../pages/Reports/Efficiency";
import EfficiencyReportSettings from "../pages/Reports/Efficiency/Settings";

const Home = async(() => import("../pages/Home"));
const CreateTemplate = async(() => import("../pages/Templates/CreateTemplate"));
const EditTemplate = async(() => import("../pages/Templates/EditTemplate"));
const ViewTemplate = async(() => import("../pages/Templates/ViewTemplate"));
const Dashboard = async(() => import("../pages/Dashboard"));
const Holiday = async(() => import("../pages/Reports/Quote/Holiday"));
const QuoteCostInputs = async(() =>
  import("../pages/Reports/Quote/CostInputs")
);

const CustomerTrialsLog = async(() => import("../pages/TrialsLog/Customers"));

const TrialsLog = async(() => import("../pages/TrialsLog/TrialsLog"));

const TgeCategorySales = async(() =>
  import("../pages/Reports/TgeCategorySales")
);
const Assessments = async(() => import("../pages/HumanResources/Assessments"));

const Classifications = async(() =>
  import("../pages/HumanResources/Classifications")
);

const Departments = async(() => import("../pages/HumanResources/Departments"));
const EmployeeAssessment = async(() =>
  import("../pages/HumanResources/EmployeeAssessment")
);
const Employees = async(() => import("../pages/HumanResources/Employees"));

const ListNineBoxReport = async(() =>
  import("../pages/HumanResources/NineBoxReport/ListNineBoxReport")
);
const DetailNineBoxReport = async(() =>
  import("../pages/HumanResources/NineBoxReport/DetailNineBoxReport")
);
const Positions = async(() => import("../pages/HumanResources/Position/"));
const WinLoss = async(() => import("../pages/Reports/WinLoss"));
const ListUsers = async(() => import("../pages/UserManagement/ListUsers"));
const DieData = async(() => import("../pages/RotaryDie/DieData"));

const DieDetailReport = async(() =>
  import("../pages/RotaryDie/DieDetailReport")
);
const RochefortDashboard = async(() => import("../pages/Rochefort/Dashboard"));
const ListKzooQuotes = async(() => import("../pages/Kzoo/Quotes/List"));
const CreateKzooQuote = async(() => import("../pages/Kzoo/Quotes/Create"));

const PriceComplements = async(() =>
  import("../pages/Kzoo/Quotes/Settings/PriceComplements")
);

const EditPriceIncrease = async(() =>
  import("../pages/Kzoo/Quotes/Settings/PriceIncreases/Edit")
);
const ListKzooCustomers = async(() => import("../pages/Kzoo/Customers/List"));
const EditKzooCustomer = async(() => import("../pages/Kzoo/Customers/Edit"));
const FlexDieQuote = async(() => import("../pages/Kzoo/FlexDieQuote"));
const BattleshipSvgKzoo = async(() => import("../pages/Kzoo/Battleship"));

const Device = async(() => import("../pages/RotaryDie/Settings/Device"));

const DieCustomer = async(() =>
  import("../pages/RotaryDie/Settings/DieCustomer")
);

const CylindersList = async(() => import("../pages/RotaryDie/ListCylinder"));
const DiesInventoryList = async(() =>
  import("../pages/RotaryDie/ListDiesInventory")
);

const QuoteReport = async(() =>
  import("../pages/Reports/Quote/Settings/QuoteReport")
);

const Sow = async(() => import("pages/Sow"));
const SowCustomer = async(() => import("pages/Sow/Customer"));
const SowInventory = async(() => import("pages/Sow/Inventory"));
const PartsCatalogs = async(() =>
  import("pages/PartsCatalog/PartsCatalogItems")
);

const PartsCatalogsCards = async(() =>
  import("pages/PartsCatalog/PartsCatalogCards")
);
const PartsCatalogRequestForQuotes = async(() =>
  import("pages/PartsCatalog/RequestForQuotes")
);

const CustomerPreferencePage = async(() =>
  import("pages/CustomerForms/Tables/CustomerPreference")
);

const BernalRetoolPage = async(() =>
  import("pages/CustomerForms/Tables/BernalRetool")
);

const MidwayRetoolPage = async(() =>
  import("pages/CustomerForms/Tables/MidwayRetool")
);

const RegisterCustomerPreferencePage = async(() =>
  import("pages/CustomerForms/Register/CustomerPreference")
);

const RegisterBernalRetoolPage = async(() =>
  import("pages/CustomerForms/Register/BernalRetool")
);

const RegisterMidwayRetoolPage = async(() =>
  import("pages/CustomerForms/Register/MidwayRetool")
);

const homeRoutes = {
  id: "Home",
  path: "/",
  icon: <HomeIcon />,
  children: null,
  containsHome: false,
  component: Home,
};

const dashboardRoutes = {
  id: "Dashboards",
  path: "/dashboards",
  header: "PnL Analysis",
  image: dashboard,
  icon: <BarChart />,
  appId: 6,
  children: null,
  containsHome: false,
  component: Dashboard,
};

const templatesRoutes = {
  id: "Templates",
  path: "/templates",
  icon: <BookOpen />,
  appId: 5,
  children: [
    {
      path: "/templates/view",
      name: "View",
      component: ViewTemplate,
    },
    {
      path: "/templates/create",
      name: "Create",
      component: CreateTemplate,
    },
    {
      path: "/templates/edit/:templateGroupId",
      name: "Edit",
      component: EditTemplate,
    },
  ],
  containsHome: false,
  component: null,
};

const templatesSidebarRoutes = {
  id: "Templates",
  header: "Templates RPA",
  path: "/templates",
  icon: <BookOpen />,
  image: templates,
  appId: 5,
  children: [
    {
      path: "/templates/view",
      name: "View",
    },
    {
      path: "/templates/create",
      name: "Create",
    },
  ],
  containsHome: false,
  component: null,
};

const quotesReports = {
  id: "Quote",
  path: "/reports/quote",
  icon: <Box />,
  header: "Reports",
  image: reports,
  appId: 7,
  children: [
    {
      path: "/reports/quote/view",
      name: "Report",
      component: Quote,
    },
    {
      path: "/reports/quote/settings",
      name: "Settings",
      component: QuoteReport,
    },
    {
      path: "/reports/quote/costinputs",
      component: QuoteCostInputs,
      name: "Cost Inputs",
    },
    {
      path: "/reports/quote/holidays",
      component: Holiday,
      name: "Holidays",
    },
  ],
  containsHome: false,
  component: null,
};

const efficiencyReports = {
  id: "Efficiency",
  path: "/reports/efficiency",
  icon: <Award />,
  appId: 7,
  children: [
    {
      path: "/reports/efficiency/view",
      name: "Report",
      component: Efficiency,
    },
    {
      path: "/reports/efficiency/settings",
      name: "Settings",
      component: EfficiencyReportSettings,
    },
  ],
  containsHome: false,
  component: null,
};

const tgeCategorySales = {
  id: "Sales",
  path: "/reports/tgecategorysales",
  icon: <BarChart2 />,
  appId: 7,
  children: [
    {
      path: "/reports/tgecategorysales/view",
      name: "Report",
      component: TgeCategorySales,
    },
  ],
  containsHome: false,
  component: null,
};

const winLoss = {
  id: "WinLoss",
  path: "/reports/winloss",
  icon: <DollarSign />,
  appId: 7,
  children: [
    {
      path: "/reports/winloss/view",
      name: "Report",
      component: WinLoss,
    },
  ],
  containsHome: false,
  component: null,
};

const rochefortReports = {
  id: "Rochefort",
  path: "/reports/rochefort",
  image: reports,
  icon: <Box />,
  appId: 7,
  children: [
    {
      path: "/reports/rochefort/view",
      name: "Dashboard",
      component: RochefortDashboard,
    },
  ],
  containsHome: false,
  component: null,
};

const sowRoutes = {
  id: "Statement of Work",
  path: "/sow",
  header: "SoW",
  image: folding,
  icon: <DollarSign />,
  appId: 12,
  children: [
    {
      path: "/sow",
      name: "Sales",
      component: Sow,
    },
    {
      path: "/sow/customer",
      name: "Customer",
      component: SowCustomer,
    },
    {
      path: "/sow/inventory",
      name: "Inventory",
      component: SowInventory,
    },
  ],
  component: null,
};

const customerFormsRoutes = {
  id: "Historic submissions",
  path: "/customerforms/tables",
  icon: <Sliders />,
  image: customerService,
  appId: 15,
  children: [
    {
      path: "/customerforms/tables/customerpreference",
      name: "Customer preference",
      component: CustomerPreferencePage,
    },
    {
      path: "/customerforms/tables/bernalretool",
      name: "Bernal retool",
      component: BernalRetoolPage,
    },
    {
      path: "/customerforms/tables/midwayretool",
      name: "Midway retool",
      component: MidwayRetoolPage,
    },
  ],
  containsHome: false,
  component: null,
};

const registerCustomerFormsRoutes = {
  id: "Service",
  path: "/customerforms/register",
  icon: <Award />,
  header: "Customer Forms",
  image: customerService,
  appId: 15,
  children: [
    {
      path: "/customerforms/register/customerpreference",
      name: "Customer preference",
      component: RegisterCustomerPreferencePage,
    },
    {
      path: "/customerforms/register/bernalretool",
      name: "Bernal retool",
      component: RegisterBernalRetoolPage,
    },
    {
      path: "/customerforms/register/midwayretool",
      name: "Midway retool",
      component: RegisterMidwayRetoolPage,
    },
  ],
  containsHome: false,
  component: null,
};

const partsCatalogsRoutes = {
  id: "Parts Catalog",
  path: "/partscatalog",
  header: "Parts Catalog",
  image: manufacture,
  icon: <Tool />,
  appId: 14,
  children: [
    {
      path: "/partscatalog/cards",
      name: "Cards",
      component: PartsCatalogsCards,
    },
    {
      path: "/partscatalog/items",
      name: "Items",
      component: PartsCatalogs,
    },
    {
      path: "/partscatalog/request-for-quotes",
      name: "Request for quotes",
      component: PartsCatalogRequestForQuotes,
    },
  ],
  component: null,
};

const humanResourceRoutes = {
  id: "Managment",
  path: "/humanresources",
  icon: <Users />,
  header: "Human Resources",
  image: humanResources,
  appId: 8,
  children: [
    {
      path: "/humanresources/assessments",
      name: "Assessments",
      component: Assessments,
    },
    {
      path: "/humanresources/classifications",
      name: "Classifications",
      component: Classifications,
    },
    {
      path: "/humanresources/department",
      name: "Departments",
      component: Departments,
    },
    {
      path: "/humanresources/employeeassessments",
      name: "Employee assessments",
      component: EmployeeAssessment,
    },
    {
      path: "/humanresources/employees",
      name: "Employees",
      component: Employees,
    },
    {
      path: "/humanresources/positions",
      name: "Positions",
      component: Positions,
    },
  ],
  component: null,
};

const humanResourceActionsRoutes = {
  id: "Reports",
  path: "/humanresources",
  icon: <Users />,
  appId: 8,
  children: [
    {
      path: "/humanresources/nineboxreport/:assessmentId",
      name: "Nine Box Report",
      component: ListNineBoxReport,
    },
    {
      path: "/humanresources/nineboxreport/:employeeAssessmentId/:employeeId",
      name: "Nine Box Report Detail",
      component: DetailNineBoxReport,
    },
  ],
  containsHome: false,
};

const trialsLogRoutes = {
  id: "Trials log",
  header: "Log Trials",
  image: trialLogs,
  path: "/trialslog",
  icon: <UserCheck />,
  appId: 9,
  children: [
    {
      path: "/trialslog/trialslog/view",
      name: "View",
      component: TrialsLog,
    },
    {
      path: "/trialslog/customer/view",
      name: "Customers",
      component: CustomerTrialsLog,
    },
  ],
  containsHome: false,
  component: null,
};

const userManagementRoutes = {
  id: "Users",
  path: "/usermanagement",
  header: "User Management",
  image: userManagementImg,
  icon: <User />,
  appId: 10,
  children: null,
  containsHome: false,
  component: ListUsers,
};

const rotaryDieRoutes = {
  id: "Rotary Die",
  path: "/rotarydie/data",
  header: "Rotary Die IoT",
  image: rotaryDieImg,
  icon: <Speed />,
  appId: 11,
  children: [
    {
      path: "/rotarydie/data",
      name: "Die data",
      component: DieData,
    },
  ],
  component: null,
};

const rotaryDieActionsRoutes = {
  id: "Rotary Die",
  path: "/rotarydie/data",
  icon: <Speed />,
  appId: 11,
  children: [
    {
      path: "/rotarydie/diedetailreport/:dieId",
      component: DieDetailReport,
    },
  ],
  containsHome: false,
};

const rotaryDieSettingsRoutes = {
  id: "Settings",
  path: "/rotarydie/settings",
  icon: <Settings />,
  appId: 11,
  children: [
    {
      path: "/rotarydie/settings/device",
      name: "Device",
      component: Device,
    },
    {
      path: "/rotarydie/settings/customer",
      name: "Customer",
      component: DieCustomer,
    },
  ],
  component: null,
};

const rotaryCylindersRoutes = {
  id: "Inventory",
  path: "/rotarydie/invetory",
  icon: <Box />,
  appId: 11,
  children: [
    {
      path: "/rotarydie/invetory/cylinders",
      name: "Cylinders",
      component: CylindersList,
    },
    {
      path: "/rotarydie/invetory/dies",
      name: "Dies",
      component: DiesInventoryList,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  isAnonymous: true,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/confirm-sign-up/:signUpUsername",
      name: "Confirm Sign Up",
      component: ConfirmSignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/confirm-reset-password/:resetPasswordUsername",
      name: "Confirm password",
      component: ConfirmResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/403",
      name: "403 Page",
      component: Page403,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const kzooQuotesRoute = {
  id: "Quotes",
  path: "/kzooquotes",
  header: "Kzoo Quotes",
  image: quoteImage,
  icon: <Clipboard />,
  appId: 13,
  children: null,
  component: ListKzooQuotes,
};

const kzooQuotesActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  appId: 13,
  children: [
    {
      path: "/kzooquotes/create",
      component: CreateKzooQuote,
    },
  ],
  containsHome: false,
};

const kzooSettingsRoutes = {
  id: "Settings",
  path: "/kzooquotes/settings",
  icon: <Box />,
  appId: 13,
  children: [
    {
      path: "/kzooquotes/settings/complements",
      name: "Price Complements",
      component: PriceComplements,
    },
  ],
  component: null,
};

const kzoosettingsActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  icon: <Box />,
  children: [
    {
      path: "/kzooquotes/settings/increases/create",
      component: EditPriceIncrease,
    },
    {
      path: "/kzooquotes/settings/increases/edit/:idParam",
      component: EditPriceIncrease,
    },
  ],
};

const kzooCustomersRoute = {
  id: "Customers",
  path: "/kzooquotes/customers",
  icon: <User />,
  appId: 13,
  children: null,
  component: ListKzooCustomers,
};

const kzooSvgRoutes = {
  id: "Flex Quote",
  path: "/kzooquotes/flexquote",
  icon: <FileText />,
  children: null,
  appId: 13,
  component: FlexDieQuote,
};

const BattleshipSvgKzooRoutes = {
  id: "Battleship",
  path: "/kzooquotes/battleshipsvg",
  icon: <Table />,
  appId: 13,
  children: null,
  component: BattleshipSvgKzoo,
};

const kzooCustomerActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  icon: <User />,
  appId: 13,
  children: [
    {
      path: "/kzooquotes/customers/create",
      component: EditKzooCustomer,
    },
    {
      path: "/kzooquotes/customers/edit/:idParam",
      component: EditKzooCustomer,
    },
  ],
};

export const mainLayoutRoutes = [
  homeRoutes,
  dashboardRoutes,
  templatesRoutes,
  kzooQuotesRoute,
  kzooQuotesActionsRoutes,
  kzooSettingsRoutes,
  kzoosettingsActionsRoutes,
  kzooCustomersRoute,
  kzooCustomerActionsRoutes,
  kzooSvgRoutes,
  BattleshipSvgKzooRoutes,
  rotaryDieRoutes,
  rotaryDieActionsRoutes,
  rotaryDieSettingsRoutes,
  rotaryCylindersRoutes,
  quotesReports,
  efficiencyReports,
  humanResourceRoutes,
  humanResourceActionsRoutes,
  tgeCategorySales,
  winLoss,
  trialsLogRoutes,
  sowRoutes,
  customerFormsRoutes,
  registerCustomerFormsRoutes,
  partsCatalogsRoutes,
  userManagementRoutes,
  rochefortReports,
];

export const authLayoutRoutes = [authRoutes];

export const sidebarRoutes = [
  homeRoutes,
  dashboardRoutes,
  templatesSidebarRoutes,
  kzooQuotesRoute,
  kzooSettingsRoutes,
  kzooCustomersRoute,
  kzooSvgRoutes,
  BattleshipSvgKzooRoutes,
  rotaryDieRoutes,
  rotaryDieSettingsRoutes,
  rotaryCylindersRoutes,
  quotesReports,
  efficiencyReports,
  tgeCategorySales,
  winLoss,
  rochefortReports,
  humanResourceRoutes,
  trialsLogRoutes,
  sowRoutes,
  registerCustomerFormsRoutes,
  customerFormsRoutes,
  partsCatalogsRoutes,
  userManagementRoutes,
];

/*

const financialReportsRoutes = {
  id: "Financial",
  path: "/reports/financial",
  header: "Reports",
  icon: <Sliders />,
  children: [
    {
      path: "/reports/financial/revenue",
      name: "Revenue",
      component: FinancialRevenue,
      guard: AuthGuard,
    },
  ],
  containsHome: false,
  component: null,
};

const quotesRoutes = {
  id: "Quotes",
  path: "/quotes",
  icon: <Sliders />,
  children: [
    {
      path: "/quotes/customerData",
      name: "Customer Data",
      component: CustomerData,
      guard: AuthGuard,
    },
    {
      path: "/quotes/customer",
      name: "Customer Catalog",
      component: Customer,
      guard: AuthGuard,
    },
  ],
  containsHome: false,
  component: null,
};

const kzooSettingsRoutes = {
  id: "Settings",
  path: "/kzooquotes/settings",
  header: "KzooQuotes",
  icon: <Sliders />,
  children: [
    {
      path: "/kzooquotes/settings/increases",
      name: "Price Increases",
      component: ListPriceIncreases,
      guard: AuthGuard,
    },
    {
      path: "/kzooquotes/settings/complements",
      name: "Price Complements",
      component: ListPriceComplements,
      guard: AuthGuard,
    },
  ],
  containsHome: false,
  component: null,
};

const kzoosettingsActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  icon: <Sliders />,
  children: [
    {
      path: "/kzooquotes/settings/increases/create",
      component: EditPriceIncrease,
    },
    {
      path: "/kzooquotes/settings/increases/edit/:idParam",
      component: EditPriceIncrease,
    },
    {
      path: "/kzooquotes/settings/complements/create",
      component: EditPriceComplements,
    },
    {
      path: "/kzooquotes/settings/complements/edit/:idParam",
      component: EditPriceComplements,
    },
  ],
  containsHome: false,
  guard: AuthGuard,
};

const kzooCustomersRoute = {
  id: "Customers",
  path: "/customers",
  icon: <Sliders />,
  children: null,
  containsHome: false,
  component: ListKzooCustomers,
  guard: AuthGuard,
};

const kzooQuotesRoute = {
  id: "Quotes",
  path: "/kzooquotes",
  icon: <Sliders />,
  children: null,
  containsHome: false,
  component: ListKzooQuotes,
  guard: AuthGuard,
};

const kzooQuotesActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  icon: <Sliders />,
  children: [
    {
      path: "/kzooquotes/create",
      component: CreateKzooQuote,
    },
  ],
  containsHome: false,
  guard: AuthGuard,
};

const kzooCustomerActionsRoutes = {
  id: "KzooQuotes",
  path: "/kzooquotes",
  icon: <Sliders />,
  children: [
    {
      path: "/customers/create",
      component: EditKzooCustomer,
    },
    {
      path: "/customers/edit/:idParam",
      component: EditKzooCustomer,
    },
  ],
  containsHome: false,
  guard: AuthGuard,
};

const fileRoutes = {
  id: "Files",
  path: "/files",
  icon: <Sliders />,
  children: null,
  containsHome: false,
  component: Files,
  guard: AuthGuard,
};

const fileParamsRoutes = {
  id: "Files",
  path: "/files",
  icon: <Sliders />,
  children: [
    {
      path: "/files/:tabParam",
      component: Files,
    },
  ],
  containsHome: false,
  component: Files,
  guard: AuthGuard,
};

const customerRoutes = {
  id: "Quotes",
  path: "/quotes",
  icon: <Sliders />,
  children: [
    {
      path: "/quotes/customer/create",
      component: EditCustomer,
    },
  ],
  containsHome: false,
  component: Files,
  guard: AuthGuard,
};

*/
