import { Fragment, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { spacing } from "@material-ui/system";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReportTable from "../../../components/ReportTable";
import useQuoteReport from "../../../hooks/useQuoteReportOut";
import useQuoteReportCatalogs from "../../../hooks/useQuoteReportCatalogs";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const FilterContainer = styled(Grid)`
  padding: ${(props) => props.theme.spacing(5)}px;
`;
const FilterButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const schema = yup.object().shape({
  startDate: yup.string().required("The start date is required"),
  endDate: yup.string().required("The end date  is required"),
});

const Quote = () => {
  const { divisions } = useQuoteReportCatalogs();

  const { handleSubmit, control, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      division: "",
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const { data, getReportOut } = useQuoteReport();

  const handleFilter = (form) => {
    const dateStart = format(getValues("startDate"), "yyyy-MM-dd");
    const dateEnd = format(getValues("endDate"), "yyyy-MM-dd");
    getReportOut(dateStart, dateEnd, getValues("division"));
  };

  useEffect(() => {
    if (divisions && divisions.length > 0) {
      setValue(`division`, divisions[0]);
    }

    const timeout = setTimeout(() => {
      getReportOut(
        format(getValues("startDate"), "yyyy-MM-dd"),
        format(getValues("endDate"), "yyyy-MM-dd"),
        getValues("division")
      );
    }, 2000);
    return () => clearTimeout(timeout);
  }, [divisions, setValue, getReportOut, getValues]);

  return (
    <>
      <Helmet title="Quote Report" />

      <Typography variant="h3" gutterBottom display="inline">
        Quote Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Reports</Typography>
        <Typography>Quote</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <form onSubmit={handleSubmit(handleFilter)}>
        <FilterContainer
          container
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={4}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={6} md={2}>
              <Controller
                name="startDate"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { invalid, error },
                }) => (
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Select the start date"
                    format="MM/dd/yyyy"
                    value={getValues("startDate")}
                    onChange={(_) => setValue("startDate", _)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Controller
                name="endDate"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { invalid, error },
                }) => (
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Select the end date"
                    format="MM/dd/yyyy"
                    value={getValues("endDate")}
                    onChange={(_) => setValue("endDate", _)}
                  />
                )}
              />
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={6} md={2}>
            <Controller
              name="division"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <Autocomplete
                  id="division"
                  options={divisions}
                  value={getValues("division") ? getValues("division") : null}
                  getOptionLabel={({ division }) => division || ""}
                  ListboxProps={{ style: { maxHeight: "15rem" } }}
                  renderInput={(params) => (
                    <TextField
                      style={{ marginBottom: 8 }}
                      {...params}
                      label="Select a division"
                      error={invalid}
                      helperText={invalid && error.message}
                      {...field}
                      onChange={() => {}}
                    />
                  )}
                  onChange={(_, data) => {
                    if (!data) {
                      setValue("division", "");
                      return;
                    }
                    setValue("division", data);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <FilterButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
            >
              Filter
            </FilterButton>
          </Grid>
        </FilterContainer>
      </form>
      {data && <ReportTable data={data} />}
    </>
  );
};

export default Quote;
