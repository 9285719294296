import * as types from "../../constants";

const initialState = {
  data: {
    fileHistory: null,
    pendingFiles: null,
  },
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FILE_API_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case types.FILE_API_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case types.FILE_API_IN_FAILURE:
      const data = {
        fileHistory: actions.isFileHistory ? null : state.data.fileHistory,
        pendingFiles: actions.isPendingFiles ? null : state.data.pendingFiles,
      };

      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          message: actions.message,
          detail: actions.error,
        },
        data,
      };
    case types.FILE_API_RESET:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: null,
      };
    case types.FILE_API_GET_FILE_HISTORY:
      return {
        ...state,
        data: { ...state.data, fileHistory: actions.data },
      };
    case types.FILE_API_GET_PENDING_FILES:
      return {
        ...state,
        data: { ...state.data, pendingFiles: actions.data },
      };
    default:
      return state;
  }
}
