import * as types from "../../constants";

const initialState = {
  kzooquotesData: null,
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.KZOOQUOTE_API_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case types.KZOOQUOTE_API_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case types.KZOOQUOTE_API_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          message: actions.message,
          detail: actions.error,
        },
      };
    case types.KZOOQUOTE_API_RESET:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: null,
      };
    case types.KZOOQUOTE_GET_QUOTE:
      return {
        ...state,
        kzooquotesData: actions.data ? actions.data : [],
      };
    case types.KZOOQUOTE_ADD_QUOTE:
      return {
        ...state,
        kzooquotesData: actions.data ? actions.data : [],
      };
    default:
      return state;
  }
}
