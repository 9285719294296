import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { mainLayoutRoutes, authLayoutRoutes } from "./index";

// Guards
import AuthGuardComponent from "../components/AuthGuard";

import MainLayout from "../layouts/Main";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

const childRoutes = (Layout, routes) =>
  routes.map(
    ({ component: Component, children, path, appId, isAnonymous }, index) => {
      const guardProps = isAnonymous ? {} : { appId };
      const AuthGuard = isAnonymous ? React.Fragment : AuthGuardComponent;

      return children ? (
        children.map((element, index) => {
          const ElementComponent = element.component || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props) => (
                <Layout>
                  <AuthGuard {...guardProps}>
                    <ElementComponent {...props} />
                  </AuthGuard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <AuthGuard {...guardProps}>
                <Component {...props} />
              </AuthGuard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(MainLayout, mainLayoutRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
