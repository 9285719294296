import { useState, useEffect, useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import * as type from "../../constants";
import {
  getTables as getTablesService,
  getFields as getFieldsService,
  getDivision as getDivisionService,
} from "../../services/quoteReportService";

const useQuoteReportCatalogs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [tables, setTables] = useState([]);
  const [quoteFields, setQuoteFields] = useState([]);
  const [shippedFields, setShippedFields] = useState([]);
  const [ordersFields, setOrdersFields] = useState([]);
  const [divisions, setDivisions] = useState([]);

  const getData = useCallback(async () => {
    setIsLoading(true);
    setTables(null);
    setDivisions(null);
    try {
      const response = await Promise.all([
        getTablesService(),
        getDivisionService(),
      ]);

      const responseTables = response[0];
      const responseDivisions = response[1];

      setTables(responseTables);
      setDivisions(responseDivisions);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(type.QUOTE_SETTING_GET_SORRY, {
        variant: type.ERROR,
      });

      setIsLoading(false);
      setTables(null);
      setDivisions(null);
    }
  }, [enqueueSnackbar]);

  const getQuoteFields = useCallback(
    async (table) => {
      setQuoteFields([]);
      try {
        const data = await getFieldsService({ table });

        if (data) {
          setQuoteFields(data);
        }
      } catch (err) {
        enqueueSnackbar(type.QUOTE_REPORT_FIELD_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const getShippedFields = useCallback(
    async (table) => {
      setShippedFields([]);
      try {
        const data = await getFieldsService({ table });

        if (data) {
          setShippedFields(data);
        }
      } catch (err) {
        enqueueSnackbar(type.QUOTE_REPORT_FIELD_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const getOrdersFields = useCallback(
    async (table) => {
      setOrdersFields([]);
      try {
        const data = await getFieldsService({ table });

        if (data) {
          setOrdersFields(data);
        }
      } catch (err) {
        enqueueSnackbar(type.QUOTE_REPORT_FIELD_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const defaultDivision = useMemo(() => {
    if (divisions && divisions.length > 0) {
      return divisions.find((item) => item.division === "Midway");
    }
  }, [divisions]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading,
    tables: tables ? tables : [],
    divisions: divisions ? divisions : [],
    defaultDivision,
    quoteFields,
    shippedFields,
    ordersFields,
    setQuoteFields,
    setShippedFields,
    setOrdersFields,
    getQuoteFields,
    getShippedFields,
    getOrdersFields,
  };
};

export default useQuoteReportCatalogs;
