import * as types from "../../constants";

const initialState = {
  excelData: null,
  sheetData: {},
  openExcelModal: false,
  currentTabModal: 0,
  selectedCells: null,
  currentSelectedCells: null,
  showSelected: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.EXCEL_INFO_SET:
      return {
        ...state,
        excelData: actions.payload.excelData,
        sheetData: actions.payload.sheetData,
      };

    case types.EXCEL_INFO_MODAL_OPEN_SET:
      return {
        ...state,
        openExcelModal: actions.payload.openExcelModal,
      };

    case types.EXCEL_INFO_MODAL_CURRENT_TAB_SET:
      return {
        ...state,
        currentTabModal: actions.payload.tab,
      };

    case types.EXCEL_INFO_MODAL_SELECTED_SET:
      return {
        ...state,
        selectedCells: actions.payload.selected,
      };

    case types.EXCEL_INFO_MODAL_CURRENT_SELECTED_SET:
      return {
        ...state,
        currentSelectedCells: actions.payload.selected,
      };

    case types.EXCEL_INFO_MODAL_SELECTED_SHOW_INFO_SET:
      return {
        ...state,
        showSelected: actions.payload.showSelected,
      };

    case types.EXCEL_INFO_RESET:
      return initialState;

    default:
      return state;
  }
}
