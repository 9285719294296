import * as types from "../../constants";

const initialState = {
  items: [],
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.CART_ADD:
      return {
        ...state,
        items: [...actions.payload.items],
      };
    case types.CART_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
