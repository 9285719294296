import { useState, useCallback, useRef } from "react";
import CollapseTable from "components/CollapseTable";
import BaseFormDialog from "components/Dialogs/BaseFormDialog";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { getActions as getEfficiencyReportSettingActions } from "./EfficiencyReportSettingForm";
import useEfficiencyReportSettings from "hooks/useEfficiencyReportSettings";

const efficiencySettingHeader = [
  {
    id: "division_name",
    label: "Division name",
  },
  {
    id: "std_hours_table",
    label: "Std hours table",
  },
  {
    id: "std_hours_workcenter",
    label: "Std hours workcenter",
  },
  {
    id: "std_hours_product",
    label: "Std hours product",
  },
  {
    id: "act_hours_table",
    label: "Act hours table",
  },
  {
    id: "act_hours_workcenter",
    label: "Act hours workcenter",
  },
  {
    id: "act_hours_product",
    label: "Act hours product",
  },
];

const EfficiencyReportSetting = () => {
  const { isLoading, data, reloadData } = useEfficiencyReportSettings();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [formProps, setFormProps] = useState(null);
  const [confirmDialogProps, setConfirmDialogProps] = useState(null);
  const contentComponent = useRef(null);

  const loadFormDialog = useCallback(({ formProperties, component }) => {
    setFormProps(formProperties);
    contentComponent.current = component;
    setOpenDialog(true);
  }, []);

  const loadConfirmationDialog = useCallback(({ dialogProperties }) => {
    setConfirmDialogProps(dialogProperties);
    setOpenConfirmationDialog(true);
  }, []);

  const handleCloseFormDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleCloseConfirmationDialog = useCallback(() => {
    setOpenConfirmationDialog(false);
  }, []);

  const handleCallback = useCallback(() => {
    reloadData();
    handleCloseFormDialog();
    handleCloseConfirmationDialog();
  }, [reloadData, handleCloseFormDialog, handleCloseConfirmationDialog]);

  const efficiencyReportTableConfig = {
    title: "Efficiency report setting",
    actions: getEfficiencyReportSettingActions(
      loadFormDialog,
      loadConfirmationDialog,
      handleCallback
    ),
  };

  const formDialogProps = {
    open: openDialog,
    onClose: handleCloseFormDialog,
    maxWidth: "md",
    formProps,
    contentComponent: contentComponent.current,
  };

  const confirmationDialogProps = {
    open: openConfirmationDialog,
    onClose: handleCloseConfirmationDialog,
    dialogProps: confirmDialogProps,
  };

  return (
    <>
      <CollapseTable
        data={data}
        header={efficiencySettingHeader}
        tableConfig={efficiencyReportTableConfig}
        isLoading={isLoading}
      />
      {openDialog && <BaseFormDialog {...formDialogProps}></BaseFormDialog>}
      {openConfirmationDialog && (
        <ConfirmationDialog {...confirmationDialogProps}></ConfirmationDialog>
      )}
    </>
  );
};

export default EfficiencyReportSetting;
