import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Slide,
  LinearProgress,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components/macro";
import { useSnackbar } from "notistack";
import * as type from "constants/index";

const TitleContainer = styled(MuiDialogTitle)`
  margin: 0;
  background-color: ${(props) => props.theme.sidebar.background};
`;

const PrimaryActionButton = styled(Button)`
  text-transform: uppercase;
  color: inherit;
`;

const SecondaryActionContainer = styled.div`
  flex-grow: 1;
`;

const SecondaryActionButton = styled(Button)`
  text-transform: uppercase;
  float: left;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.spacing(1)}px;
  top: ${(props) => props.theme.spacing(1)}px;
  color: #9e9e9e;
`;

const TitleText = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.sidebar.color};
`;

const FullscreenTitleText = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
  flex: 1;
`;

const FullscreenTitleContainer = styled(AppBar)`
  position: relative;
  background-color: ${(props) => props.theme.sidebar.background};
`;

const FullSreenDialogContent = styled.div`
  width: 100%;
  padding: ${(props) => props.theme.spacing(4)}px;
`;

const getDialogProps = (props) => {
  const defaultProps = {
    title: null,
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    fullScreen: false,
  };

  return {
    ...defaultProps,
    ...props,
    notificationMessage: {
      ...defaultProps.notificationMessage,
      ...props.notificationMessage,
    },
  };
};

const loadActions = (actions, handleAction) => {
  let primaryAction = <></>;
  let secondaryActions = <></>;

  if (actions) {
    const { primary, secondary } = actions;

    if (primary) {
      const { label, ...otherProps } = primary;
      primaryAction = (
        <PrimaryActionButton
          onClick={() => handleAction(otherProps)}
          color="primary"
        >
          {label}
        </PrimaryActionButton>
      );
    }

    if (secondary) {
      secondaryActions = (
        <SecondaryActionContainer>
          {secondary.map(({ label, ...otherProps }, index) => (
            <SecondaryActionButton
              key={index}
              onClick={() => handleAction(otherProps)}
              color="primary"
            >
              {label}
            </SecondaryActionButton>
          ))}
        </SecondaryActionContainer>
      );
    }
  }

  return { primaryAction, secondaryActions };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = (props) => {
  const { children, onClose, fullScreen, actionsComponent, ...other } = props;
  return fullScreen ? (
    <FullscreenTitleContainer>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <FullscreenTitleText variant="h6">{children}</FullscreenTitleText>
        {actionsComponent}
      </Toolbar>
    </FullscreenTitleContainer>
  ) : (
    <TitleContainer disableTypography {...other}>
      <TitleText variant="h6">{children}</TitleText>
      {onClose ? (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </TitleContainer>
  );
};

const BaseDialog = ({
  open,
  onClose,
  dialogProps: dialogPropsParameter,
  children,
  ...rest
}) => {
  const dialogProps = getDialogProps(dialogPropsParameter);

  const {
    title,
    disableEscapeKeyDown,
    disableBackdropClick,
    fullScreen,
    actions,
  } = dialogProps;

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const wrapperCallback = (callback) => Promise.resolve(callback());

  const handleAction = async ({
    action: callback,
    closeDialog = true,
    useNotification = false,
    notificationMessage = null,
  }) => {
    try {
      setIsLoading(true);
      await wrapperCallback(callback);
      setIsLoading(false);

      if (useNotification) {
        enqueueSnackbar(
          notificationMessage.success || type.GENERIC_API_SUCCESS_MESSAGE,
          {
            variant: type.SUCCESS,
          }
        );
      }
    } catch (error) {
      console.error(error);

      enqueueSnackbar(
        notificationMessage.error || type.GENERIC_API_ERROR_MESSAGE,
        {
          variant: type.ERROR,
        }
      );
    }

    if (closeDialog) {
      onClose();
    }
  };

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason && reason === "backdropClick") {
      return;
    }

    onClose();
  };

  const { primaryAction, secondaryActions } = loadActions(
    actions,
    handleAction
  );

  const actionsComponent = (
    <>
      {secondaryActions}
      <PrimaryActionButton onClick={handleClose}>
        {dialogPropsParameter?.confirmationProps?.cancelLabel || "Cancel"}
      </PrimaryActionButton>
      {primaryAction}
    </>
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen={fullScreen}
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {title && (
        <DialogTitle
          actionsComponent={actionsComponent}
          fullScreen={fullScreen}
          onClose={handleClose}
        >
          {title}
        </DialogTitle>
      )}
      {isLoading && <LinearProgress color="primary" />}
      {fullScreen ? (
        <FullSreenDialogContent>{children}</FullSreenDialogContent>
      ) : (
        <>
          <DialogContent dividers>{children}</DialogContent>
          <DialogActions>{actionsComponent}</DialogActions>
        </>
      )}
    </Dialog>
  );
};

const action = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  closeDialog: PropTypes.bool,
  useNotification: PropTypes.bool,
  notificationMessage: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
};

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogProps: PropTypes.shape({
    title: PropTypes.string,
    disableEscapeKeyDown: PropTypes.bool,
    disableBackdropClick: PropTypes.bool,
    fullScreen: PropTypes.bool,
    actions: PropTypes.shape({
      primary: PropTypes.shape(action),
      secondary: PropTypes.arrayOf(PropTypes.shape(action)),
    }),
  }),
};

export default BaseDialog;
