export const colors = (alpha, numberOfColors) => {
  const colors = [
    `rgba(255,  99, 132, ${alpha})`,
    `rgba( 54, 162, 235, ${alpha})`,
    `rgba(255, 206,  86, ${alpha})`,
    `rgba( 75, 192, 192, ${alpha})`,
    `rgba(153, 102, 255, ${alpha})`,
    `rgba(255, 159,  64, ${alpha})`,
    `rgba(224, 187, 228, ${alpha})`,
    `rgba(112, 198, 220, ${alpha})`,
    `rgba(162, 183, 135, ${alpha})`,
    `rgba(147, 155, 235, ${alpha})`,
    `rgba(153, 235, 100, ${alpha})`,
    `rgba(253, 182, 213, ${alpha})`,
    `rgba(177, 138, 189, ${alpha})`,
    `rgba(134, 174, 209, ${alpha})`,
    `rgba(248, 195, 182, ${alpha})`,
    `rgba(248, 235, 191, ${alpha})`,
    `rgba(174, 188, 110, ${alpha})`,
    `rgba(103, 154, 125, ${alpha})`,
    `rgba(224, 254, 254, ${alpha})`,
    `rgba(255, 218, 193, ${alpha})`,
  ];

  return colors.slice(0, numberOfColors);
};

export default colors;
