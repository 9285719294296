import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { getReportOut as getReportOutService } from "../../services/quoteReportService";

import * as type from "../../constants";

const useQuoteReportOut = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);

  const getReportOut = useCallback(
    async (date_start, date_end, division) => {
      setData(null);
      try {
        const data = await getReportOutService({
          date_start,
          date_end,
          division: division.id,
        });

        if (data) {
          setData(data);
        }
      } catch (err) {
        enqueueSnackbar(type.QUOTE_REPORT_OUT_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  return {
    data,
    getReportOut,
  };
};

export default useQuoteReportOut;
