import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import styled from "styled-components/macro";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  setItemsInCart,
  resetCart,
  removeItemFromCart,
} from "../../redux/actions/cartActions";
import ShoppingItem from "components/PartsCatalog/ShoppingItem/index";
import { useSnackbar } from "notistack";
import * as type from "constants/index";
import EmptyCartImg from "../../vendor/images/empty_cart.png";
import {
  processCart as processCartService,
  processCartNotification as processCartNotificationService,
} from "services/partsCatalog/partsCatalogItemService";

const CartIconContainerButton = styled(IconButton)`
  margin-right: 8px;
`;

const Title = styled(Typography)`
  padding-top: 16px;
  padding-left: 16px;
`;

const ListItemCart = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ListItemTextWithDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemoveItemLink = styled(Link)`
  cursor: pointer;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 8px;
  padding-right: 16px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const BuyButton = styled(Button)`
  width: 100px;
  height: 35px;
`;

const CartEmptyMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CartEmptyImage = styled.img`
  width: 50%;
`;

const useStyles = makeStyles({
  list: {
    width: 400,
  },
  fullList: {
    width: "auto",
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const DrawerCart = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { items } = useSelector((state) => state.cartReducer);
  const total = items?.reduce((accumulator, object) => {
    return accumulator + object.quantity * object.price;
  }, 0);

  const totalItems = items?.reduce((accumulator, object) => {
    return accumulator + object.quantity;
  }, 0);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  useEffect(() => {
    dispatch(setItemsInCart());
  }, [dispatch]);

  const handleProcessCart = () => {
    if (items?.length > 0) {
      setIsProcessing(true);
      processCartService(items)
        .then(() => {
          dispatch(resetCart());
          setIsProcessing(false);

          enqueueSnackbar(type.CART_SAVED, {
            variant: "success",
            autoHideDuration: 3000,
          });
          sendProcessCartNotification();
        })
        .catch(() => {
          enqueueSnackbar(type.CART_SAVED_SORRY, {
            variant: "error",
          });
          setIsProcessing(false);
        });
    }
  };

  const sendProcessCartNotification = () => {
    processCartNotificationService()
      .then(() => {
        enqueueSnackbar(type.PROCESS_CART_NOTIFICATION, {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch(() => {
        enqueueSnackbar(type.PROCESS_CART_NOTIFICATION_SORRY, {
          variant: "error",
        });
      });
  };

  const handleRemoveItemFromCart = (itemId) => {
    try {
      dispatch(removeItemFromCart(itemId));
      enqueueSnackbar(type.CART_ITEM_REMOVED, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } catch {
      enqueueSnackbar(type.CART_ITEM_REMOVED_SORRRY, {
        variant: "error",
      });
    }
  };

  const list = () => (
    <div className={clsx(classes.list)} role="presentation">
      <Title variant={"h3"} gutterBottom>
        Cart
      </Title>
      <List>
        {items?.map(
          ({ item, name, description, pic, quantity, price }, index) => (
            <div key={index}>
              <ListItemCart>
                <ListItemTextWithDeleteContainer>
                  <ListItemText primary={name} />
                  <RemoveItemLink
                    onClick={() => handleRemoveItemFromCart(item)}
                  >
                    Delete
                  </RemoveItemLink>
                </ListItemTextWithDeleteContainer>
                <ShoppingItem
                  key={item}
                  id={item}
                  name={name}
                  description={description}
                  pic={pic}
                  price={price}
                  quantity={quantity}
                  handleUpdateStorage
                />
              </ListItemCart>
              <Divider />
            </div>
          )
        )}
      </List>
      {items?.length > 0 ? (
        <>
          {total && (
            <TotalContainer>
              <Typography variant={"h4"}>
                Curent Total: <b> ${total?.toLocaleString("en-US")}</b>
              </Typography>
            </TotalContainer>
          )}

          <ActionContainer>
            <BuyButton
              variant="contained"
              color="primary"
              onClick={handleProcessCart}
              disabled={isProcessing}
            >
              {isProcessing && <CircularProgress size={14} />}
              {!isProcessing && "Buy"}
            </BuyButton>
          </ActionContainer>
        </>
      ) : (
        <CartEmptyMessageContainer>
          <CartEmptyImage src={EmptyCartImg} />
        </CartEmptyMessageContainer>
      )}
    </div>
  );

  return (
    <>
      <CartIconContainerButton aria-label="cart" onClick={toggleDrawer(true)}>
        <StyledBadge
          badgeContent={totalItems}
          color="secondary"
          overlap="rectangular"
        >
          <ShoppingCartIcon />
        </StyledBadge>
      </CartIconContainerButton>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {list("right")}
      </Drawer>
    </>
  );
};

export default DrawerCart;
