import * as types from "../../constants";

const initialState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.CLAIRTECH_API_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case types.CLAIRTECH_API_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case types.CLAIRTECH_API_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          message: actions.message,
          detail: actions.error,
        },
      };
    case types.CLAIRTECH_API_RESET:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: null,
      };
    case types.CLAIRTECH_API_GET_TEMPLATE:
      return {
        ...state,
        data: actions.data,
      };
    case types.CLAIRTECH_API_DELETE_TEMPLATE:
      return state;
    default:
      return state;
  }
}
