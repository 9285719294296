import * as types from "../../constants";

export function setTheme(value) {
  return {
    type: types.THEME_SET,
    payload: value,
  };
}

export function setSidebar(value) {
  localStorage.setItem(types.SIDEBAR_SET, value);
  return {
    type: types.SIDEBAR_SET,
    payload: value,
  };
}
