import { TextField, Grid, Typography, Card } from "@material-ui/core";
import styled from "styled-components/macro";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as type from "constants/index";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import * as Yup from "yup";
import {
  addEfficiencyRerportSettings as addEfficiencyRerportSettingsService,
  updateEfficiencyReportSettings as updateEfficiencyReportSettingsService,
  deleteEfficiencyReportSettings as removeEfficiencyReportSettingsService,
  findDivision as findDivisionService,
} from "services/efficiencyReportSettingsService";
import useEfficiencyReportCatalogs from "hooks/useEfficiencyReportCatalogs";
import { useCallback, useEffect } from "react";

const LabelField = styled(Typography)`
  display: block;
  font-weight: 600;
  min-width: 80px;
`;

const CardContainer = styled(Card)`
  padding: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: 2rem;
`;

const defaultValues = {
  division: null,
  stdHoursTable: null,
  stdHoursDate: null,
  stdHoursDivisionFilterField: null,
  stdHoursDivisionFilterValue: "",
  stdHoursEmployee: null,
  stdHoursWorkcenter: null,
  stdHoursDepartment: null,
  stdHoursJobId: null,
  stdHoursProduct: null,
  stdHoursTime: null,
  actHoursTable: null,
  actHoursDate: null,
  actHoursDivisionFilterField: null,
  actHoursDivisionFilterValue: "",
  actHoursEmployee: null,
  actHoursWorkcenter: null,
  actHoursDepartment: null,
  actHoursJobId: null,
  actHoursProduct: null,
  actHoursTime: null,
  actHoursStartTime: null,
  actHoursEndTime: null,
};

const validationSchema = Yup.object().shape({
  division: Yup.object().nullable().required("Division is required"),
  stdHoursTable: Yup.string()
    .nullable()
    .required("The standard table is required"),
  stdHoursDate: Yup.string()
    .nullable()
    .required("The standard hours date is required"),
  stdHoursDivisionFilterField: Yup.string()
    .nullable()
    .required("The standard hours division filter field is required"),
  stdHoursDivisionFilterValue: Yup.string()
    .nullable()
    .required("The standard hours division filter value is required"),
  stdHoursEmployee: Yup.string()
    .nullable()
    .required("The standard hours employee is required"),
  stdHoursWorkcenter: Yup.string()
    .nullable()
    .required("The standard hours workcenter is required"),
  stdHoursDepartment: Yup.string()
    .nullable()
    .required("The standard hours department is required"),
  stdHoursJobId: Yup.string()
    .nullable()
    .required("The standard hours job id is required"),
  stdHoursProduct: Yup.string()
    .nullable()
    .required("The standard hours product is required"),
  stdHoursTime: Yup.string()
    .nullable()
    .required("The standard hours time is required"),
  actHoursTable: Yup.string()
    .nullable()
    .required("The actual table is required"),
  actHoursDate: Yup.string()
    .nullable()
    .required("The actual hours date is required"),
  actHoursDivisionFilterField: Yup.string()
    .nullable()
    .required("The actual hours division filter field is required"),
  actHoursDivisionFilterValue: Yup.string().required(
    "The actual hours division filter value is required"
  ),
  actHoursEmployee: Yup.string()
    .nullable()
    .required("The actual hours employee is required"),
  actHoursWorkcenter: Yup.string()
    .nullable()
    .required("The actual hours workcenter is required"),
  actHoursDepartment: Yup.string()
    .nullable()
    .required("The actual hours department is required"),
  actHoursJobId: Yup.string()
    .nullable()
    .required("The actual hours job id is required"),
  actHoursProduct: Yup.string()
    .nullable()
    .required("The actual hours product is required"),
  actHoursTime: Yup.string()
    .nullable()
    .required("The actual hours time is required"),
  actHoursStartTime: Yup.string()
    .nullable()
    .required("The actual hours start time is required"),
  actHoursEndTime: Yup.string().required(
    "The actual hours end time is required"
  ),
});

export const getActions = (loadForm, loadConfirmDialog, callback) => {
  const addFunc = () => {
    const formProperties = {
      title: "Add efficiency report setting",
      defaultValues,
      validationSchema,
      onSubmit: async (data) => {
        const request = {
          division: data.division.id,
          std_hours_table: data.stdHoursTable,
          std_hours_date: data.stdHoursDate,
          std_hours_division_filter_field: data.stdHoursDivisionFilterField,
          std_hours_division_filter_value: data.stdHoursDivisionFilterValue,
          std_hours_employee: data.stdHoursEmployee,
          std_hours_workcenter: data.stdHoursWorkcenter,
          std_hours_department: data.stdHoursDepartment,
          std_hours_job_id: data.stdHoursJobId,
          std_hours_product: data.stdHoursProduct,
          std_hours_time: data.stdHoursTime,
          act_hours_table: data.actHoursTable,
          act_hours_date: data.actHoursDate,
          act_hours_division_filter_field: data.actHoursDivisionFilterField,
          act_hours_division_filter_value: data.actHoursDivisionFilterValue,
          act_hours_employee: data.actHoursEmployee,
          act_hours_workcenter: data.actHoursWorkcenter,
          act_hours_department: data.actHoursDepartment,
          act_hours_job_id: data.actHoursJobId,
          act_hours_product: data.actHoursProduct,
          act_hours_time: data.actHoursTime,
          act_hours_start_time: data.actHoursStartTime,
          act_hours_end_time: data.actHoursEndTime,
        };
        await addEfficiencyRerportSettingsService(request);

        callback();
      },
      onError: (error) => {
        console.error(error);
      },
      actionsProps: {
        acceptLabel: "Add",
      },
      messages: {
        success: type.EFFICIENCY_REPORT_SETTING_ADD,
        error: type.EFFICIENCY_REPORT_SETTING_ADD_SORRY,
      },
    };

    loadForm({ formProperties, component: EfficiencyReportSettingsForm });
  };

  const editFunc = async (data) => {
    const divisionResponse = await findDivisionService(data.division);

    const editValues = {
      division: divisionResponse,
      stdHoursTable: data.std_hours_table,
      stdHoursDate: data.std_hours_date,
      stdHoursDivisionFilterField: data.std_hours_division_filter_field,
      stdHoursDivisionFilterValue: data.std_hours_division_filter_value,
      stdHoursEmployee: data.std_hours_employee,
      stdHoursWorkcenter: data.std_hours_workcenter,
      stdHoursDepartment: data.std_hours_department,
      stdHoursJobId: data.std_hours_job_id,
      stdHoursProduct: data.std_hours_product,
      stdHoursTime: data.std_hours_time,
      actHoursTable: data.act_hours_table,
      actHoursDate: data.act_hours_date,
      actHoursDivisionFilterField: data.act_hours_division_filter_field,
      actHoursDivisionFilterValue: data.act_hours_division_filter_value,
      actHoursEmployee: data.act_hours_employee,
      actHoursWorkcenter: data.act_hours_workcenter,
      actHoursDepartment: data.act_hours_department,
      actHoursJobId: data.act_hours_job_id,
      actHoursProduct: data.act_hours_product,
      actHoursTime: data.act_hours_time,
      actHoursStartTime: data.act_hours_start_time,
      actHoursEndTime: data.act_hours_end_time,
    };

    const formProperties = {
      title: "Edit efficiency report setting",
      defaultValues: editValues,
      validationSchema,
      onSubmit: async (editedData) => {
        const request = {
          division: editedData.division.id,
          std_hours_table: editedData.stdHoursTable,
          std_hours_date: editedData.stdHoursDate,
          std_hours_division_filter_field:
            editedData.stdHoursDivisionFilterField,
          std_hours_division_filter_value:
            editedData.stdHoursDivisionFilterValue,
          std_hours_employee: editedData.stdHoursEmployee,
          std_hours_workcenter: editedData.stdHoursWorkcenter,
          std_hours_department: editedData.stdHoursDepartment,
          std_hours_job_id: editedData.stdHoursJobId,
          std_hours_product: editedData.stdHoursProduct,
          std_hours_time: editedData.stdHoursTime,
          act_hours_table: editedData.actHoursTable,
          act_hours_date: editedData.actHoursDate,
          act_hours_division_filter_field:
            editedData.actHoursDivisionFilterField,
          act_hours_division_filter_value:
            editedData.actHoursDivisionFilterValue,
          act_hours_employee: editedData.actHoursEmployee,
          act_hours_workcenter: editedData.actHoursWorkcenter,
          act_hours_department: editedData.actHoursDepartment,
          act_hours_job_id: editedData.actHoursJobId,
          act_hours_product: editedData.actHoursProduct,
          act_hours_time: editedData.actHoursTime,
          act_hours_start_time: editedData.actHoursStartTime,
          act_hours_end_time: editedData.actHoursEndTime,
        };

        await updateEfficiencyReportSettingsService(request, data.id);

        callback();
      },
      onError: (error) => {
        console.error(error);
      },
      actionsProps: {
        acceptLabel: "Save",
      },
      messages: {
        success: type.EFFICIENCY_REPORT_SETTING_SAVE,
        error: type.EFFICIENCY_REPORT_SETTING_SAVE_SORRY,
      },
      requireConfirmation: true,
      confirmationProps: {
        title: "Alert",
        message:
          "Are you sure you want to edit this efficiency report setting?",
      },
    };

    loadForm({ formProperties, component: EfficiencyReportSettingsForm });
  };

  const removeFunc = (data) => {
    const dialogProperties = {
      title: "Alert",
      message:
        "Are you sure you want to delete this efficiency report setting?",
      callback: async () => {
        await removeEfficiencyReportSettingsService(data.id);
        callback();
      },
      useNotification: true,
      notificationMessage: {
        success: type.EFFICIENCY_REPORT_SETTING_REMOVE,
        error: type.EFFICIENCY_REPORT_SETTING_REMOVE_SORRY,
      },
    };

    loadConfirmDialog({ dialogProperties });
  };

  return {
    add: addFunc,
    edit: editFunc,
    remove: removeFunc,
  };
};

const EfficiencyReportSettingsForm = () => {
  const { control, resetField } = useFormContext();

  const size = { xs: 12, sm: 6, md: 4, lg: 3 };
  const {
    tables,
    divisions,
    stdFields,
    actFields,
    setStdFields,
    setActFields,
    getStdFields,
    getActFields,
  } = useEfficiencyReportCatalogs();

  const stdHoursTable = useWatch({
    control,
    name: "stdHoursTable",
  });

  const actHoursTable = useWatch({
    control,
    name: "actHoursTable",
  });

  const resetStdFields = useCallback(() => {
    setStdFields([]);
    resetField("stdHoursTable");
    resetField("stdHoursDate");
    resetField("stdHoursDivisionFilterField");
    resetField("stdHoursEmployee");
    resetField("stdHoursWorkcenter");
    resetField("stdHoursDepartment");
    resetField("stdHoursJobId");
    resetField("stdHoursProduct");
    resetField("stdHoursTime");
  }, [setStdFields, resetField]);

  const resetActFields = useCallback(() => {
    setActFields([]);
    resetField("actHoursTable");
    resetField("actHoursDate");
    resetField("actHoursDivisionFilterField");
    resetField("actHoursEmployee");
    resetField("actHoursWorkcenter");
    resetField("actHoursDepartment");
    resetField("actHoursJobId");
    resetField("actHoursProduct");
    resetField("actHoursTime");
    resetField("actHoursStartTime");
    resetField("actHoursEndTime");
  }, [setActFields, resetField]);

  useEffect(() => {
    if (!stdHoursTable) {
      resetStdFields();
      return;
    }
    getStdFields(stdHoursTable);
  }, [stdHoursTable, getStdFields, resetStdFields]);

  useEffect(() => {
    if (!actHoursTable) {
      resetActFields();
      return;
    }
    getActFields(actHoursTable);
  }, [actHoursTable, getActFields, resetActFields]);

  return (
    <>
      <CardContainer>
        <LabelField>Division</LabelField>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
            <Controller
              name="division"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  options={divisions}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.division}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select the division"
                      error={invalid}
                      helperText={invalid && error.message}
                    />
                  )}
                  onChange={(_, data) => onChange(data)}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContainer>

      <CardContainer>
        <LabelField> Standard fields</LabelField>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
            <Controller
              name="stdHoursDivisionFilterValue"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  label="Standard hours division filter value"
                  fullWidth
                  error={invalid}
                  helperText={invalid && error.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
            <Controller
              name="stdHoursTable"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  options={tables}
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Table"
                      error={invalid}
                      helperText={invalid && error.message}
                    />
                  )}
                  onChange={(_, data) => onChange(data)}
                />
              )}
            />
          </Grid>

          {stdFields && stdFields.length > 0 && (
            <>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursDivisionFilterField"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours division filter field"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursDate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours date"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursEmployee"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours employee"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursWorkcenter"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours workcenter"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursDepartment"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours department"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursJobId"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours job id"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursProduct"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours product"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="stdHoursTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={stdFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Standard hours time"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContainer>

      <CardContainer>
        <LabelField> Actual fields</LabelField>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
            <Controller
              name="actHoursDivisionFilterValue"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  label="Actual hours division filter value"
                  fullWidth
                  error={invalid}
                  helperText={invalid && error.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
            <Controller
              name="actHoursTable"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  options={tables}
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Table"
                      error={invalid}
                      helperText={invalid && error.message}
                    />
                  )}
                  onChange={(_, data) => onChange(data)}
                />
              )}
            />
          </Grid>

          {actFields && actFields.length > 0 && (
            <>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursDivisionFilterField"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours division filter field"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursDate"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours date"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursEmployee"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours employee"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursWorkcenter"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours workcenter"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursDepartment"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours department"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursJobId"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours job id"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursProduct"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours product"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours time"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursStartTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours time"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={size.xs} sm={size.sm} md={size.md} lg={size.lg}>
                <Controller
                  name="actHoursEndTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      options={actFields}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Actual hours time"
                          error={invalid}
                          helperText={invalid && error.message}
                        />
                      )}
                      onChange={(_, data) => onChange(data)}
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContainer>
    </>
  );
};
