import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import {
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
  Popper,
  Fade,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { sidebarRoutes } from "../../routes/index";

import LogoNormal from "../../vendor/logo.png";
import LogoMinimal from "../../vendor/logoMinimal.png";

import { SIDEBAR_TYPE } from "../../constants";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }

  & img {
    max-height: 50px;
  }
`;

const CategoryPopper = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &::before {
    content: " ";
    background: none;
    width: 3px;
    height: 90%;
    position: absolute;
    left: 0;
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    &::before {
      content: " ";
      background: white;
      width: 3px;
      height: 90%;
      position: absolute;
      left: 0;
    }

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    &::before {
      content: " ";
      background: white;
      width: 3px;
      height: 90%;
      position: absolute;
      left: 0;
    }

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const LinkPopover = styled(ListItem)`
  min-width: 150px;
  padding: 0;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
    padding-left: ${(props) => props.theme.spacing(4)}px;
    padding-top: ${(props) => props.theme.spacing(3)}px;
    padding-bottom: ${(props) => props.theme.spacing(3)}px;
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    &::before {
      content: " ";
      background: white;
      width: 3px;
      height: 90%;
      position: absolute;
      left: 0;
    }

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SideBarLinkPopoverContainer = styled(Popper)`
  & div {
    background-color: ${(props) => props.theme.sidebar.background};
  }

  & hr {
    background-color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  sidebarType,
  hasChildren,
  ...rest
}) => {
  if (sidebarType === SIDEBAR_TYPE.MINIMAL) {
    const TooltipComponent = !hasChildren ? Tooltip : React.Fragment;
    const tooltipProps = !hasChildren
      ? { title: name, placement: "right" }
      : null;

    return (
      <TooltipComponent {...tooltipProps}>
        <Category id={`SC-${name}`} {...rest}>
          {icon}
          {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>
      </TooltipComponent>
    );
  }

  return (
    <Category id={`SC-${name}`} {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>

      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarPopperHeader = ({ name }) => {
  return (
    <>
      <CategoryPopper id={`SC-${name}`} disableGutters>
        <CategoryText>{name}</CategoryText>
      </CategoryPopper>
      <Divider />
    </>
  );
};

const SidebarLink = ({ name, to, badge, icon }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const SidebarLinkPopover = ({ name, to, badge, icon }) => {
  return (
    <LinkPopover
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </LinkPopover>
  );
};

const Sidebar = ({
  classes,
  staticContext,
  location,
  sidebarType,
  ...rest
}) => {
  const [routes, setRoutes] = useState([]);
  const user = useSelector((state) => state.authReducer.user);
  const [openRoutes, setOpenRoutes] = useState([]);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  useEffect(() => {
    if (user) {
      const filterRouters = sidebarRoutes.filter(({ appId }) =>
        appId ? user.appPermissionList.includes(appId) : true
      );

      setRoutes(filterRouters);
    }
  }, [user]);

  useEffect(() => {
    if (routes) {
      const pathName = location.pathname;

      let _routes = {};

      routes.forEach((route, index) => {
        const isActive = pathName.indexOf(route.path) === 0;
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/";

        _routes = Object.assign({}, _routes, {
          [index]: isActive || isOpen || isHome,
        });
      });
      setOpenRoutes(_routes);
    }
  }, [routes, location.pathname]);

  const handlePopoverOpen = (event, menuItem) => {
    setPopoverAnchorEl({ anchorEl: event.currentTarget, menuItem });
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const getInitials = (text) => {
    // RPA
    if (!text) return "ND";

    const words = text.split(" ");
    let initials = "";

    if (words.length > 1) {
      initials = words[0].charAt(0) + words[1].charAt(0);
    } else if (text.length > 1) {
      initials = text.charAt(0) + text.charAt(1);
    } else {
      initials = text.charAt(0);
    }

    return initials.toUpperCase();
  };

  const isSidebarNormal = sidebarType === SIDEBAR_TYPE.NORMAL;
  const isSidebarMinimal = sidebarType === SIDEBAR_TYPE.MINIMAL;

  const Logo = isSidebarMinimal ? LogoMinimal : LogoNormal;

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand
        component={NavLink}
        to="/"
        button
        style={{ backgroundColor: "white" }}
      >
        <img src={Logo} width="100%" alt="logo" />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <>
                    {isSidebarMinimal && (
                      <Tooltip placement="right" title={category.header}>
                        <SidebarSection>
                          {getInitials(category.header)}
                        </SidebarSection>
                      </Tooltip>
                    )}

                    {isSidebarNormal && (
                      <SidebarSection>{category.header}</SidebarSection>
                    )}
                  </>
                ) : null}

                {category.children && category.icon ? (
                  <>
                    {isSidebarNormal && (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          button={true}
                          onClick={() => toggle(index)}
                          sidebarType={sidebarType}
                          hasChildren={true}
                        />
                        <Collapse
                          in={openRoutes[index]}
                          timeout="auto"
                          unmountOnExit
                        >
                          {category.children.map((route, index) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                            />
                          ))}
                        </Collapse>
                      </React.Fragment>
                    )}
                    {isSidebarMinimal && (
                      <>
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.id}
                          icon={category.icon}
                          button
                          sidebarType={sidebarType}
                          hasChildren={true}
                          onMouseEnter={(event) =>
                            handlePopoverOpen(event, category.id)
                          }
                        />
                        <ClickAwayListener onClickAway={handlePopoverClose}>
                          <SideBarLinkPopoverContainer
                            open={
                              Boolean(popoverAnchorEl) &&
                              popoverAnchorEl.menuItem === category.id
                            }
                            anchorEl={
                              popoverAnchorEl ? popoverAnchorEl.anchorEl : null
                            }
                            placement="right-start"
                            transition
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps} timeout={500}>
                                <div>
                                  <SidebarPopperHeader name={category.id} />
                                  {category.children.map((route, index) => (
                                    <SidebarLinkPopover
                                      key={index}
                                      name={route.name}
                                      to={route.path}
                                      icon={route.icon}
                                      badge={route.badge}
                                    />
                                  ))}
                                </div>
                              </Fade>
                            )}
                          </SideBarLinkPopoverContainer>
                        </ClickAwayListener>
                      </>
                    )}
                  </>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                    sidebarType={sidebarType}
                    hasChildren={false}
                    onMouseEnter={handlePopoverClose}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);
