import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./../UserDropdown";
import { useSelector, useDispatch } from "react-redux";

import { setSidebar } from "../../redux/actions/themeActions";
import { SIDEBAR_TYPE } from "../../constants";

import DrawerCart from "../DrawerCart/index";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const UserAuthenticated = styled(Typography)`
  text-transform: capitalize;
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const auth = useSelector((state) => state.authReducer);
  const sidebarType = useSelector((state) => state.themeReducer.sidebar);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    switch (sidebarType) {
      case SIDEBAR_TYPE.NORMAL:
        dispatch(setSidebar(SIDEBAR_TYPE.MINIMAL));
        break;
      case SIDEBAR_TYPE.MINIMAL:
        dispatch(setSidebar(SIDEBAR_TYPE.NORMAL));
        break;
      default:
        dispatch(setSidebar(SIDEBAR_TYPE.NORMAL));
        break;
    }
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item>
                <IconButton color="inherit" onClick={handleSidebar}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <DrawerCart />
            </Grid>
            <Grid item>
              {auth && auth.user && (
                <UserAuthenticated variant="h6">
                  {auth.user.name}
                </UserAuthenticated>
              )}
            </Grid>
            <Grid item>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
