import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Dialog, DialogContent } from "@material-ui/core";

import _ from "lodash";

ChartJS.register(...registerablesJS);
ChartJS.register(
  LinearScale,
  CategoryScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const getOptions = (title) => ({
  responsive: true,
  aspectRatio: 3,
  locale: "en-US",
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: title,
    },
    datalabels: {
      display: true,
      anchor: "center",
      align: "top",
      font: { weight: "bold" },
      backgroundColor: "#ffffff",
      borderRadius: 4,
      padding: {
        top: 4,
        right: 3,
        bottom: 2,
        left: 3,
      },
      formatter: (value) => value.toLocaleString("en-US"),
    },
  },
});

const removeTotalsRows = (bodyOrdered) => {
  let bodyOrderedinner = [];
  let bodyOrderedFilter = [];

  bodyOrdered.forEach((element) => {
    bodyOrderedinner = [];
    element.forEach((innerElement) => {
      if (innerElement.style) {
        if (innerElement.style.includes("total")) {
          return;
        }
      }
      bodyOrderedinner.push(innerElement);
    });

    if (bodyOrderedinner.length > 0) bodyOrderedFilter.push(bodyOrderedinner);
  });
  return bodyOrderedFilter;
};

const orderData = (data) => _.chain(data).sortBy(["position"], ["asc"]).value();

const processData = (
  { header, body },
  { positionForLabel, positionForData, colorForData, colorForBorder }
) => {
  const headerOrdered = orderData(header);
  const bodyOrdered = removeTotalsRows(orderData(body));
  const positionForDataOrdered = _.chain(positionForData)
    .sortBy(["asc"])
    .value();

  const labels = bodyOrdered.map((item) => {
    const { value } = item.find(
      ({ position: bodyPosition }) => bodyPosition === positionForLabel
    );

    return value;
  });

  var datasets = positionForDataOrdered.map((positionData, index) => {
    const { value: headerValue } = headerOrdered[0].find(
      ({ position: headerPosition }) => headerPosition === positionData
    );

    const label = !!headerValue ? headerValue : "Undefined";

    const data = bodyOrdered.map((bodyItem) => {
      const result = bodyItem.find(
        ({ position: bodyPosition }) => bodyPosition === positionData
      );

      if (!result) return undefined;
      const bodyValue = result.value;

      const numberValue = Number(bodyValue.replace(/[^0-9.-]+/g, ""));

      return numberValue;
    });

    return {
      label,
      data,
      backgroundColor: colorForData,
      borderColor: colorForBorder,
      borderWidth: 1,
    };
  });

  return {
    labels,
    datasets,
  };
};

const DoughnutChart = ({ open, onClose, data, chartConfig }) => {
  const { title } = data;
  const chartData = processData(data, chartConfig);

  const options = getOptions(title);

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={onClose}>
      <DialogContent>
        <Chart
          type="doughnut"
          data={chartData}
          plugins={[ChartDataLabels]}
          options={options}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DoughnutChart;
