import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import * as type from "../../constants";
import {
  getTables as getTablesService,
  getFields as getFieldsService,
  getDivision as getDivisionService,
} from "../../services/efficiencyReportSettingsService";

const useEfficiencyReportCatalogs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [division, setDivision] = useState([]);
  const [stdFields, setStdFields] = useState([]);
  const [actFields, setActFields] = useState([]);

  const getData = useCallback(async () => {
    setIsLoading(true);
    setTables([]);
    setDivision([]);
    try {
      const response = await Promise.all([
        getTablesService(),
        getDivisionService(),
      ]);

      if (response) {
        setTables(response[0]);
        setDivision(response[1]);
      }
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(type.EFFICIENCY_REPORT_CATALOG_GET_SORRY, {
        variant: "error",
      });

      setIsLoading(false);
      setTables([]);
      setDivision([]);
    }
  }, [enqueueSnackbar]);

  const getStdFields = useCallback(
    async (table) => {
      setStdFields([]);
      try {
        const data = await getFieldsService({ table });

        if (data) {
          setStdFields(data);
        }
      } catch (err) {
        enqueueSnackbar(type.EFFICIENCY_REPORT_FIELD_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  const getActFields = useCallback(
    async (table) => {
      setActFields([]);
      try {
        const data = await getFieldsService({ table });

        if (data) {
          setActFields(data);
        }
      } catch (err) {
        enqueueSnackbar(type.EFFICIENCY_REPORT_FIELD_GET_SORRY, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading,
    tables: tables,
    divisions: division,
    stdFields,
    actFields,
    setStdFields,
    setActFields,
    getStdFields,
    getActFields,
  };
};

export default useEfficiencyReportCatalogs;
