import { Button } from "@material-ui/core";
import styled from "styled-components/macro";

const BaseButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.sidebar.background};
  text-transform: uppercase;

  ${(props) =>
    props.variant === "contained" &&
    `
  background-color: ${props.theme.sidebar.background};
  color: ${props.theme.sidebar.color};
  &:hover {
    background-color: ${props.theme.sidebar.background};
    color: ${props.theme.sidebar.color};
    opacity: 90%;
  }
  `}
`;

export default BaseButton;
