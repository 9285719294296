import axios from "../../utils/clairtechAxios";

const {
  REACT_APP_API_PARTS_CATALOG,
  REACT_APP_API_PARTS_CATALOG_CART,
  REACT_APP_API_PARTS_CATALOG_GET_CART,
  REACT_APP_API_PARTS_CATALOG_PROCESS_CART_NOTIFICATION,
  REACT_APP_API_PARTS_CATALOG_CART_SHOW_RFQS,
  REACT_APP_API_PARTS_CATALOG_CART_PROCESS_RFQS,
} = process.env;

export const getPartsCatalogItems = ({ search }) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG}`, {
        params: { ...(search && { search }) },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getPartsCatalogItemsPaginated = (filters) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG}show_items/`, {
        params: filters,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const findPartsCatalogItems = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG}${id}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const addPartsCatalogItems = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_PARTS_CATALOG}`, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updatePartsCatalogItems = (data, id) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${REACT_APP_API_PARTS_CATALOG}${id}/`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const removePartsCatalogItems = (id) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${REACT_APP_API_PARTS_CATALOG}${id}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const processCart = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_PARTS_CATALOG_CART}`, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const processCartNotification = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG_PROCESS_CART_NOTIFICATION}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve();
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getShowCartItems = ({ search }) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG_GET_CART}`, {
        params: { ...(search && { search }) },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getCustomerOrders = (filters) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG_CART_SHOW_RFQS}`, {
        params: filters,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const getProcessRFQ = (filters) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_PARTS_CATALOG_CART_PROCESS_RFQS}`, {
        params: filters,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateRfqStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${REACT_APP_API_PARTS_CATALOG_CART}${id}/`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
