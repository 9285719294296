export const getChartData = (bodyOrdered) =>
  bodyOrdered.reduce((acc, current) => {
    const result = current.filter(({ show_on_chart }) => !!show_on_chart);
    return result && result.length > 0 ? [...acc, result] : acc;
  }, []);

export const getChartLabels = (headerOrdered, positionForLabel) =>
  headerOrdered.reduce((acc, current) => {
    const result = current
      .filter(
        ({ position, show_on_chart }) =>
          !!show_on_chart && position !== positionForLabel
      )
      .map((node) => node.position);
    return result && result.length > 0 ? [...acc, result] : acc;
  }, []);
