import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableRow,
  IconButton,
  TableCell,
  Collapse,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styled from "styled-components/macro";
import { format, parseISO } from "date-fns";
import { Edit as EditIcon, Trash2 as DeleteIcon } from "react-feather";
import { COLLAPSE_TABLE_VALUE_TYPES } from "constants/index";
import { getBooleanValue } from "utils/common";

const formatValueType = {
  [COLLAPSE_TABLE_VALUE_TYPES.STRING]: (value) => value.toString(),
  [COLLAPSE_TABLE_VALUE_TYPES.NUMBER]: (value) => value.toLocaleString("en-US"),
  [COLLAPSE_TABLE_VALUE_TYPES.DATE]: (value) =>
    format(parseISO(value), "MM/dd/yyyy"),
  [COLLAPSE_TABLE_VALUE_TYPES.DATETIME]: (value) =>
    format(parseISO(value), "MM/dd/yyyy HH:mm:ss"),
};

const formatValue = (value, valueType, symbol = "") => {
  if (!value) return "-";
  if (!valueType) return value;
  return `${symbol}${formatValueType[valueType](value)}`;
};

const TableCellActions = styled(TableCell)`
  min-width: 200px;
  width: 200px;
  padding: 0;
  text-align: right;
`;

const ShortTextCell = styled.div`
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CollapseCell = styled(TableCell)`
  width: 50px;
  padding: 0 ${(props) => props.theme.spacing(4)}px;
`;

const ChildComponentContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing(5)}px;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  width: 100%;
`;

export const ActionButton = styled(IconButton)`
  padding: ${(props) => props.theme.spacing(1)}px;
  margin: 0 ${(props) => props.theme.spacing(3)}px;
`;

const CollapseTableRow = ({
  data,
  parentData,
  header,
  actions,
  collapse = false,
  forceViewChild = false,
  rowStyle,
  observableState,
  children,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [observableState]);

  const collapsedRowColSpan =
    header.length + 1 + (collapse ? 1 : 0) + (actions ? 1 : 0);

  const childData =
    data.children && data.children.length > 0 ? data.children : [];

  const isChildData = childData && childData.length > 0;

  const currentData = { ...data, parentData };

  return (
    <>
      <TableRow style={rowStyle}>
        {collapse && (
          <CollapseCell>
            {forceViewChild || isChildData ? (
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </CollapseCell>
        )}

        {header.map(
          ({ id: key, type, boolLabels, withTooltip = false, ...rest }) =>
            key in data ? (
              <TableCell key={key}>
                {type === COLLAPSE_TABLE_VALUE_TYPES.BOOL ? (
                  boolLabels ? (
                    getBooleanValue(data[key]) ? (
                      boolLabels.true
                    ) : (
                      boolLabels.false
                    )
                  ) : (
                    <Checkbox
                      checked={getBooleanValue(data[key])}
                      color="primary"
                    />
                  )
                ) : withTooltip ? (
                  <Tooltip key={key} title={data[key]}>
                    <ShortTextCell>
                      {formatValue(data[key], type, rest?.symbol || "")}
                    </ShortTextCell>
                  </Tooltip>
                ) : (
                  formatValue(data[key], type, rest?.symbol || "")
                )}
              </TableCell>
            ) : (
              <></>
            )
        )}

        {actions && (
          <TableCellActions>
            {actions.other && actions.other(currentData)}
            {actions.edit && (
              <Tooltip
                title={parentData?.edit?.acceptLabel || "Edit"}
                placement="top"
              >
                <ActionButton
                  color="primary"
                  onClick={() => actions.edit(currentData)}
                >
                  <EditIcon />
                </ActionButton>
              </Tooltip>
            )}
            {actions.remove && (
              <Tooltip
                title={parentData?.delete?.acceptLabel || "Delete"}
                placement="top"
              >
                <ActionButton
                  style={{ color: "red" }}
                  onClick={() => actions.remove(currentData)}
                >
                  <DeleteIcon />
                </ActionButton>
              </Tooltip>
            )}
          </TableCellActions>
        )}
      </TableRow>
      {collapse && (forceViewChild || (isChildData && children)) && (
        <TableRow>
          <TableCell padding="none" colSpan={collapsedRowColSpan}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <ChildComponentContainer style={rowStyle}>
                {children({ data: childData, parentData: data })}
              </ChildComponentContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

CollapseTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  parentData: PropTypes.object,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      boolLabels: PropTypes.shape({
        true: PropTypes.string,
        false: PropTypes.string,
      }),
    })
  ).isRequired,
  actions: PropTypes.object,
  collapse: PropTypes.bool,
  forceViewChild: PropTypes.bool,
  rowStyle: PropTypes.object,
  observableState: PropTypes.object,
  children: PropTypes.func,
};

export default CollapseTableRow;
