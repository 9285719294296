import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/AppBar";
import Footer from "../../components/Footer";
import { SIDEBAR_TYPE } from "../../constants";
import { setSidebar } from "../../redux/actions/themeActions";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 258;
const drawerMinimalWidth = 85;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${({ drawerWidth }) => drawerWidth}px;
    flex-shrink: 0;
    transition: width 0.2s ease-out;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

const Main = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const sidebarType = useSelector((state) => state.themeReducer.sidebar);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMd) {
      dispatch(setSidebar(SIDEBAR_TYPE.NORMAL));
    }
  }, [isMd, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isMinimal = sidebarType === SIDEBAR_TYPE.MINIMAL;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer
        drawerWidth={isMinimal ? drawerMinimalWidth : drawerWidth}
        sidebarType={sidebarType}
      >
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{
              style: {
                width: isMinimal ? drawerMinimalWidth : drawerWidth,
                transition: "width 0.2s ease-out",
              },
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sidebarType={sidebarType}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{
              style: {
                width: isMinimal ? drawerMinimalWidth : drawerWidth,
                transition: "width 0.2s ease-out",
              },
            }}
            sidebarType={sidebarType}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default withWidth()(Main);
