import * as types from "../../constants";
import {} from "../../services/partsCatalog/partsCatalogItemService";

export const addItemToCart = (value) => {
  let itemsInCart = [];

  if (localStorage.getItem(types.CART_ADD)) {
    itemsInCart = JSON.parse(localStorage.getItem(types.CART_ADD));

    const itemExistInCart = itemsInCart.some((x) => x.item === value.item);
    if (itemExistInCart) {
      itemsInCart = itemsInCart.map((itemInCart) => {
        if (itemInCart.item === value.item) {
          itemInCart.quantity =
            Number(itemInCart.quantity) + Number(value.quantity);
          itemInCart.product = value.product;
          itemInCart.customerPartNumber = value.customerPartNumber;
          itemInCart.bernalDetailNum = value.bernalDetailNum;
          itemInCart.bernalOriginalJobNumber = value.bernalOriginalJobNumber;
          itemInCart.description = value.description;
        }
        return itemInCart;
      });
    } else {
      itemsInCart = [...itemsInCart, value];
    }

    localStorage.setItem(types.CART_ADD, JSON.stringify(itemsInCart));
  } else {
    localStorage.setItem(types.CART_ADD, JSON.stringify([value]));
    itemsInCart = [value];
  }

  return {
    type: types.CART_ADD,
    payload: { items: itemsInCart },
  };
};

export const updateQuantity = (value) => {
  let itemsInCart = [];

  if (localStorage.getItem(types.CART_ADD)) {
    itemsInCart = JSON.parse(localStorage.getItem(types.CART_ADD));

    const itemExistInCart = itemsInCart.some((x) => x.item === value.item);
    if (itemExistInCart) {
      itemsInCart = itemsInCart.map((itemInCart) => {
        if (itemInCart.item === value.item) {
          itemInCart.quantity = value.quantity;
        }
        return itemInCart;
      });
    }

    localStorage.setItem(types.CART_ADD, JSON.stringify(itemsInCart));
  }

  return {
    type: types.CART_ADD,
    payload: { items: itemsInCart },
  };
};

export const setItemsInCart = () => {
  const items = localStorage.getItem(types.CART_ADD)
    ? JSON.parse(localStorage.getItem(types.CART_ADD))
    : [];

  return {
    type: types.CART_ADD,
    payload: { items },
  };
};

export const removeItemFromCart = (itemId) => {
  const items = localStorage.getItem(types.CART_ADD)
    ? JSON.parse(localStorage.getItem(types.CART_ADD))
    : [];

  const itemIdx = items?.findIndex((x) => x.item === itemId);

  if (itemIdx > -1) {
    items.splice(itemIdx, 1);
    localStorage.setItem(types.CART_ADD, JSON.stringify(items));
  }

  return {
    type: types.CART_ADD,
    payload: { items },
  };
};

export const resetCart = () => {
  if (localStorage.getItem(types.CART_ADD)) {
    localStorage.removeItem(types.CART_ADD);
  }

  return {
    type: types.CART_RESET,
  };
};
