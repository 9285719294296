import PropTypes from "prop-types";
import { TableRow, TableCell } from "@material-ui/core";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CollapseTableRowSkeleton = ({ rows, cols }) => {
  return [...Array(rows)].map((_, rowIndex) => (
    <TableRow key={rowIndex}>
      {[...Array(cols)].map((_, colIndex) => (
        <TableCell key={colIndex}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  ));
};

CollapseTableRowSkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
};

export default CollapseTableRowSkeleton;
