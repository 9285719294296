import * as types from "../../constants";
import { THEMES, SIDEBAR_TYPE } from "../../constants";

const sidebarLocalStorage = localStorage.getItem(types.SIDEBAR_SET);

const initialState = {
  currentTheme: THEMES.DEFAULT,
  sidebar: sidebarLocalStorage || SIDEBAR_TYPE.NORMAL,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_SET:
      return {
        ...state,
        currentTheme: actions.payload,
      };
    case types.SIDEBAR_SET:
      return {
        ...state,
        sidebar: actions.payload,
      };
    default:
      return state;
  }
}
