import TextField from "@material-ui/core/TextField";
import Button from "components/Buttons/BaseButton";
import { useState } from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`;

const FormContainer = styled.form`
  width: 100%;
`;

const CollapseTableSearch = ({ onSearch }) => {
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(search);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClear = () => {
    setSearch("");
    onSearch(null);
  };

  return (
    <FormContainer onSubmit={handleSearch}>
      <Container>
        <TextField
          label="Search"
          type="search"
          value={search}
          onChange={handleChange}
        />
        <Button variant="contained" type="submit" disabled={!search}>
          Search
        </Button>
        <Button color="secondary" onClick={handleClear}>
          Clear
        </Button>
      </Container>
    </FormContainer>
  );
};

export default CollapseTableSearch;
