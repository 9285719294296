import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import * as type from "../../constants";
import { getEfficiencyReportSettings as getEfficiencyReportSettingsService } from "../../services/efficiencyReportSettingsService";

const useEfficiencyReportSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);

  const getData = useCallback(async () => {
    setIsLoading(true);
    setSettings([]);
    try {
      const data = await getEfficiencyReportSettingsService();

      if (data) {
        setSettings(data);
      }
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(type.EFFICIENCY_REPORT_SETTING_GET_SORRY, {
        variant: "error",
      });
      setIsLoading(false);
      setSettings([]);
    }
  }, [enqueueSnackbar]);

  const reloadData = useCallback(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    isLoading,
    data: settings,
    reloadData,
  };
};

export default useEfficiencyReportSettings;
