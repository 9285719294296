import axios from "../../utils/clairtechAxios";

const {
  REACT_APP_API_USER_MANAGEMENT_PERMITTED_APPS,
  REACT_APP_API_USER_MANAGEMENT_PERMITTED_USER_APPS,
  REACT_APP_API_USER_MANAGEMENT_CHANGE_PERMISSION,
} = process.env;

export const getLoggedUserPermittedApps = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_USER_MANAGEMENT_PERMITTED_APPS}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPermittedUserApps = (rows, page) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_USER_MANAGEMENT_PERMITTED_USER_APPS}`, {
        params: { rows, page },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeUserPermission = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_USER_MANAGEMENT_CHANGE_PERMISSION}`, null, {
        params: data,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
