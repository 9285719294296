import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import {
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import EfficiencyReportSettingsComponent from "components/Efficiency";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EfficiencyReportSettings = () => {
  return (
    <div>
      <Helmet title="Efficiency report" />

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Efficiency Report Settings </Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EfficiencyReportSettingsComponent />
    </div>
  );
};

export default EfficiencyReportSettings;
