import PropTypes from "prop-types";
import { Box as MuiBox } from "@material-ui/core";
import styled from "styled-components/macro";
import * as type from "constants/index";
import BaseDialog from "../BaseDialog";

const getDialogProps = (props) => {
  const defaultProps = {
    title: null,
    message: "Are you sure you want to perform this action?",
    useNotification: false,
    notificationMessage: {
      success: type.GENERIC_API_SUCCESS_MESSAGE,
      error: type.GENERIC_API_ERROR_MESSAGE,
    },
  };

  return {
    ...defaultProps,
    ...props,
    notificationMessage: {
      ...defaultProps.notificationMessage,
      ...props.notificationMessage,
    },
  };
};

const Box = styled(MuiBox)`
  min-width: 400px;
  padding: ${(props) => props.theme.spacing(5)}px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const ConfirmationDialog = ({
  open,
  onClose,
  dialogProps: dialogPropsParameter,
}) => {
  const dialogProps = getDialogProps(dialogPropsParameter);

  const {
    title = null,
    message = "Are you sure you want to perform this action?",
    callback,
    useNotification = false,
    notificationMessage,
  } = dialogProps;

  const baseDialogProps = {
    title,
    disableEscapeKeyDown: true,
    disableBackdropClick: true,
    actions: {
      primary: {
        label: dialogPropsParameter?.confirmationProps?.acceptLabel || "Accept",
        action: callback,
        useNotification,
        notificationMessage,
      },
    },
    confirmationProps: dialogPropsParameter?.confirmationProps,
  };

  return (
    <BaseDialog open={open} onClose={onClose} dialogProps={baseDialogProps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {message}
      </Box>
    </BaseDialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogProps: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    callback: PropTypes.func.isRequired,
    useNotification: PropTypes.bool,
    notificationMessage: PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
};

export default ConfirmationDialog;
