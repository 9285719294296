import * as types from "../../constants";

const initialState = {
  customerData: null,
  isLoading: false,
  isSuccess: false,
  error: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.KZOOCUSTOMER_API_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    case types.KZOOCUSTOMER_API_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    case types.KZOOCUSTOMER_API_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: {
          message: actions.message,
          detail: actions.error,
        },
      };
    case types.KZOOCUSTOMER_API_RESET:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: null,
      };
    case types.KZOOCUSTOMER_GET:
      return {
        ...state,
        customerData: actions.data ? actions.data.results : [],
      };
    default:
      return state;
  }
}
