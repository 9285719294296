import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { getChartData, getChartLabels } from "../../../utils/charts";
import { Dialog, DialogContent } from "@material-ui/core";

import _ from "lodash";

const getOptions = (title) => ({
  responsive: true,
  locale: "en-US",
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: title,
    },
    datalabels: {
      display: true,
      anchor: "center",
      align: "top",
      font: { weight: "bold" },
      backgroundColor: "#ffffff",
      borderRadius: 4,
      padding: {
        top: 4,
        right: 3,
        bottom: 2,
        left: 3,
      },
      formatter: (value) => value.toLocaleString("en-US"),
    },
  },
});

const orderData = (data) => _.chain(data).sortBy(["position"], ["asc"]).value();

const processData = (
  { header, body },
  { positionForLabel, positionForData, colorForData }
) => {
  const headerOrdered = getChartData(orderData(header));
  const bodyOrdered = getChartData(orderData(body));
  const positionForDataOrdered = getChartLabels(
    headerOrdered,
    positionForLabel
  )[0];

  const labels = bodyOrdered.map((item) => {
    const { value } = item.find(
      ({ position: bodyPosition }) => bodyPosition === positionForLabel
    );

    return value;
  });

  var datasets = positionForDataOrdered.map((positionData, index) => {
    const { value: headerValue } = headerOrdered[0].find(
      ({ position: headerPosition }) => headerPosition === positionData
    );

    const label = !!headerValue ? headerValue : "Undefined";

    const data = bodyOrdered
      .map((bodyItem) => {
        const result = bodyItem.find(
          ({ position: bodyPosition }) => bodyPosition === positionData
        );

        if (!result) return undefined;
        const bodyValue = result.value;

        const numberValue = Number(bodyValue.replace(/[^0-9.-]+/g, ""));

        return numberValue;
      })
      .filter((e) => e !== undefined);

    return {
      label,
      data,
      backgroundColor: colorForData[index],
    };
  });

  return {
    labels,
    datasets,
  };
};

const GroupedBar = ({ open, onClose, data, chartConfig }) => {
  const { title } = data;
  const chartData = processData(data, chartConfig);

  const options = getOptions(title);

  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={onClose}>
      <DialogContent>
        <Bar data={chartData} plugins={[ChartDataLabels]} options={options} />
      </DialogContent>
    </Dialog>
  );
};

export default GroupedBar;
