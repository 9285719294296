import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  Slide,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components/macro";
import { useSnackbar } from "notistack";
import * as type from "constants/index";
import ConfirmationDialog from "../ConfirmationDialog";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";

const getFormProps = (props) => {
  const defaultProps = {
    messages: {
      success: type.GENERIC_API_SUCCESS_MESSAGE,
      error: type.GENERIC_API_ERROR_MESSAGE,
    },
    actionsProps: {
      acceptLabel: "Submit",
      cancelLabel: "Cancel",
    },
    requireConfirmation: false,
    resetForm: false,
  };

  return {
    ...defaultProps,
    ...props,
    messages: { ...defaultProps.messages, ...props.messages },
    actionsProps: { ...defaultProps.actionsProps, ...props.actionsProps },
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TitleContainer = styled(MuiDialogTitle)`
  margin: 0;
  background-color: ${(props) => props.theme.sidebar.background};
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${(props) => props.theme.spacing(1)}px;
  top: ${(props) => props.theme.spacing(1)}px;
  color: #9e9e9e;
`;

const ActionButton = styled(Button)`
  text-transform: uppercase;
  color: ${(props) =>
    props.color === "primary" ? props.theme.sidebar.background : "inherit"};
`;

const TitleText = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.sidebar.color};
`;

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <TitleContainer disableTypography {...other}>
      <TitleText variant="h6">{children}</TitleText>
      {onClose ? (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </TitleContainer>
  );
};

const BaseFormDialog = ({
  open,
  onClose,
  maxWidth = "xs",
  formProps: formPropsParameter,
  showLoading = false,
  contentComponent,
  ...rest
}) => {
  const formProps = getFormProps(formPropsParameter);

  const {
    title,
    defaultValues,
    validationSchema,
    onSubmit,
    onError,
    messages,
    actionsProps,
    requireConfirmation = false,
    confirmationProps,
    resetForm,
  } = formProps;

  const { acceptLabel, cancelLabel } = actionsProps;

  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmitForm = () =>
    document.getElementById("primaryButton").click();

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmitForm();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  useEffect(() => {
    return () => {
      methods.reset();
      handleCloseConfirmationDialog();
    };
  }, [methods]);

  useEffect(() => {
    if (dialogProps) {
      setOpenConfirmationDialog(true);
    }
  }, [dialogProps]);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setDialogProps(null);
  };

  const handleLoading = useCallback(
    (value) => {
      if (!showLoading) return;
      setIsLoading(value);
    },
    [showLoading]
  );

  const handleSubmit = async (data) => {
    if (!requireConfirmation) {
      await processSubmit(data);
      return;
    }

    const newDialogProps = {
      ...(confirmationProps &&
        confirmationProps.title && { title: confirmationProps.title }),
      ...(confirmationProps &&
        confirmationProps.message && { message: confirmationProps.message }),
      callback: async () => await processSubmit(data),
      confirmationProps: {
        ...actionsProps,
      },
    };

    setDialogProps(newDialogProps);
  };

  const onSubmitWrapper = (data) => Promise.resolve(onSubmit(data));

  const processSubmit = async (data) => {
    try {
      handleLoading(true);
      await onSubmitWrapper(data);

      if (resetForm) {
        methods.reset();
      }

      enqueueSnackbar(messages.success, {
        variant: type.SUCCESS,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(messages.error, {
        variant: type.ERROR,
      });
    } finally {
      handleLoading(false);
    }
  };

  const handleError = (error) => {
    enqueueSnackbar(messages.error, {
      variant: type.ERROR,
    });

    onError(error);
  };

  const confirmationDialogProps = {
    open: openConfirmationDialog,
    onClose: handleCloseConfirmationDialog,
    dialogProps,
    labels: { ...rest?.labels },
  };

  const Component = contentComponent;

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            fullWidth
            fullScreen={rest?.dialogProps?.fullScreen}
            maxWidth={maxWidth}
          >
            <DialogTitle onClose={onClose}>{title}</DialogTitle>
            {showLoading && isLoading && <LinearProgress />}
            <DialogContent dividers>
              <Component {...rest} />
            </DialogContent>
            <DialogActions>
              <ActionButton onClick={onClose}>{cancelLabel}</ActionButton>
              <ActionButton
                id="primaryButton"
                color="primary"
                disabled={isLoading}
                onClick={methods.handleSubmit(
                  (data) => handleSubmit(data),
                  (error) => handleError(error)
                )}
              >
                {acceptLabel}
              </ActionButton>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
      {openConfirmationDialog && confirmationDialogProps && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </>
  );
};

BaseFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    defaultValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    messages: PropTypes.shape({
      success: PropTypes.string,
      error: PropTypes.string,
    }),
    actionsProps: PropTypes.shape({
      acceptLabel: PropTypes.string,
      cancelLabel: PropTypes.string,
    }),
    requireConfirmation: PropTypes.bool,
    confirmationProps: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
    }),
    resetForm: PropTypes.bool,
  }).isRequired,
  contentComponent: PropTypes.func.isRequired,
  showLoading: PropTypes.bool,
};

export default BaseFormDialog;
