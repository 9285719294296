import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import excelInfoReducer from "./excelInfoReducer";
import clairtechReducer from "./clairtechReducer";
import fileReducer from "./fileReducer";
import quoteReducer from "./quoteReducer";
import kzooCustomerReducer from "./kzooCustomerReducer";
import kzooquoteReducer from "./kzooquoteReducer";
import cartReducer from "./cartReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  excelInfoReducer,
  clairtechReducer,
  fileReducer,
  quoteReducer,
  kzooquoteReducer,
  kzooCustomerReducer,
  cartReducer,
});
