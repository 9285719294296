import axios from "../utils/clairtechAxios";

const {
  REACT_APP_API_EFFICIENCY_REPORT,
  REACT_APP_API_DIVISIONS,
  REACT_APP_API_EFFICIENCY_REPORT_TABLES,
  REACT_APP_API_EFFICIENCY_REPORT_FIELDS,
  REACT_APP_API_EFFICIENCY_REPORT_OUT,
} = process.env;

export const getEfficiencyReportSettings = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(REACT_APP_API_EFFICIENCY_REPORT)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const findEfficiencyReportSettings = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_EFFICIENCY_REPORT}${id}/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addEfficiencyRerportSettings = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(REACT_APP_API_EFFICIENCY_REPORT, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateEfficiencyReportSettings = (data, id) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${REACT_APP_API_EFFICIENCY_REPORT}${id}/`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEfficiencyReportSettings = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${REACT_APP_API_EFFICIENCY_REPORT}${id}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          resolve(response.status);
        }
        reject(response.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDivision = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(REACT_APP_API_DIVISIONS)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const findDivision = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_DIVISIONS}${id}/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTables = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(REACT_APP_API_EFFICIENCY_REPORT_TABLES)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFields = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(REACT_APP_API_EFFICIENCY_REPORT_FIELDS, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportOut = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(REACT_APP_API_EFFICIENCY_REPORT_OUT, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
