import axios from "../utils/clairtechAxios";

const {
  REACT_APP_API_QUOTE_REPORT,
  REACT_APP_API_QUOTE_REPORT_OUT,
  REACT_APP_API_DIVISIONS,
  REACT_APP_API_TABLES,
  REACT_APP_API_FIELDS,
} = process.env;

export const getQuoteReport = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_QUOTE_REPORT}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const findQuoteReport = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_QUOTE_REPORT}${id}/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addQuoteReport = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_QUOTE_REPORT}`, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateQuoteReport = (data, id) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${REACT_APP_API_QUOTE_REPORT}${id}/`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteQuoteReport = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${REACT_APP_API_QUOTE_REPORT}${id}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          resolve(response.status);
        }
        reject(response.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDivision = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_DIVISIONS}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const findDivision = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_DIVISIONS}${id}/`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTables = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_TABLES}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFields = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_FIELDS}`, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportOut = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${REACT_APP_API_QUOTE_REPORT_OUT}`, {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
