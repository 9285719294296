import { useState } from "react";
import colors from "../../utils/colors";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import _ from "lodash";

import GroupedBar from "../Charts/GroupedBar";
import DoughnutChart from "../Charts/DoughnutChart";
import LinearChart from "../Charts/LinearChart";

const Container = styled(TableContainer)(spacing);

const TableTitle = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  line-height: 1.9;
`;

const TitleContainer = styled(Grid)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

const useColumnStyles = makeStyles((theme) => ({
  default: theme.report.table.default,
  headerCell: theme.report.table.header,
  link: theme.report.table.link,
  success: theme.report.table.success,
  warning: theme.report.table.warning,
  danger: theme.report.table.danger,
  subtotal1: theme.report.table.subtotal1,
  subtotal2: theme.report.table.subtotal2,
  subtotal3: theme.report.table.subtotal3,
  total: theme.report.table.total,
  percentage: theme.report.table.percentage,
}));

const orderedColumns = (columns) =>
  _.chain(columns).sortBy(["position"], ["asc"]).value();

const CustomTableRow = ({ columns, isHeader = false, setFilterSubReport }) => {
  const classes = useColumnStyles();

  const handleOnClicSubReport = (link) => {
    setFilterSubReport(link);
  };

  return (
    <TableRow>
      {orderedColumns(columns).map(
        ({ value, align, link, style, colSpan, border }, index) => {
          return (
            <TableCell
              key={index}
              align={align || "left"}
              colSpan={colSpan}
              className={clsx({
                [classes.headerCell]: isHeader,
                [classes[style]]: !isHeader,
              })}
              style={{
                borderTop: `${border.top} solid #c8c8c8`,
                borderRight: `${border.right} solid #c8c8c8`,
                borderLeft: `${border.left} solid #c8c8c8`,
                borderBottom: `${border.bottom} solid #c8c8c8`,
              }}
            >
              {!!link ? (
                <Button
                  className={classes.link}
                  onClick={() => handleOnClicSubReport(link)}
                >
                  {value}
                </Button>
              ) : (
                value
              )}
            </TableCell>
          );
        }
      )}
    </TableRow>
  );
};

const ReportTable = ({ data, setFilterSubReport }) => {
  const charts = ["Linear", "Bar", "Doughnut"];
  const [openChartModal, setOpenChartModal] = useState(false);
  const [chart, setChart] = useState("");

  const { title, header, body } = data;
  const columnQty = header[0]?.length - 1;
  const rowQty = body.length;
  const dataRows = [...Array(columnQty + 1).keys()].slice(1, columnQty + 1);

  const handleOpenChartModal = () => {
    setOpenChartModal(true);
  };

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
  };

  const handleChangeChart = ({ target: { value } }) => {
    setChart(value);
  };

  return (
    <Container p={4} component={Paper}>
      <Grid container spacing={2} alignItems="center">
        <TitleContainer item xs={12} md={9}>
          <TableTitle variant="h4" color="primary">
            {title}
          </TableTitle>
        </TitleContainer>
        <TitleContainer item xs={4} md={2}>
          <TextField
            id="chart"
            select
            label="Charts"
            value={chart}
            onChange={handleChangeChart}
            helperText="Please select your chart"
          >
            {charts.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </TitleContainer>
        <TitleContainer item xs={4} md={1}>
          <Button
            color="primary"
            variant="contained"
            margin="8px"
            onClick={handleOpenChartModal}
            disabled={chart ? false : true}
          >
            View chart
          </Button>
        </TitleContainer>
      </Grid>
      <Table aria-label={title} size="small">
        <TableHead>
          {header.map((headerColumn, index) => (
            <CustomTableRow
              key={index}
              columns={headerColumn}
              isHeader={true}
            />
          ))}
        </TableHead>
        <TableBody>
          {body.map((bodyColumn, index) => (
            <CustomTableRow
              key={index}
              columns={bodyColumn}
              setFilterSubReport={setFilterSubReport}
            />
          ))}
        </TableBody>
      </Table>
      {chart === "Bar" ? (
        <GroupedBar
          open={openChartModal}
          onClose={handleCloseChartModal}
          data={data}
          chartConfig={{
            positionForLabel: 0,
            positionForData: dataRows,
            colorForData: colors(0.2, columnQty),
          }}
        />
      ) : chart === "Linear" ? (
        <LinearChart
          open={openChartModal}
          onClose={handleCloseChartModal}
          data={data}
          chartConfig={{
            positionForLabel: 0,
            positionForData: dataRows,
            colorForData: colors(0.2, rowQty),
            colorForBorder: colors(1, rowQty),
          }}
        />
      ) : (
        <DoughnutChart
          open={openChartModal}
          onClose={handleCloseChartModal}
          data={data}
          chartConfig={{
            positionForLabel: 0,
            positionForData: [1],
            colorForData: colors(0.2, rowQty),
            colorForBorder: colors(1, rowQty),
          }}
        />
      )}
    </Container>
  );
};

export default ReportTable;
