import axios from "axios";
import { Auth } from "aws-amplify";

const { REACT_APP_API_BASE_URL } = process.env;

const clairtechAxios = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

clairtechAxios.interceptors.request.use(
  async function (config) {
    const {
      accessToken: { jwtToken },
    } = await Auth.currentSession();

    config.headers.Authorization = `Bearer ${jwtToken}`;

    return config;
  },
  function (error) {
    console.error(error);
    return Promise.reject(error);
  }
);

export default clairtechAxios;
