// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const ALERT_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const ALERT_DEFAULT_DELAY = 10000;

export const ALERT_DEFAULT_MESSAGE =
  "Sorry, something went wrong validating the data";

export const SPECIAL_INITAL_STRING_VALUE = "3mpT1";

export const SIDEBAR_SET = "SIDEBAR_SET";

export const SIDEBAR_TYPE = {
  NORMAL: "NORMAL",
  MINIMAL: "MINIMAL",
};

export const COLLAPSE_TABLE_VALUE_TYPES = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  DATETIME: "DATETIME",
  BOOL: "BOOL",
};

export const STEPPER_ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const TABS_ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const ORDER_DIRECTION = {
  ASC: "asc",
  DESC: "desc",
};

// General
export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";

// Clairtech
export const CLAIRTECH_API_REQUEST = "CLAIRTECH_API_REQUEST";
export const CLAIRTECH_API_IN_SUCCESS = "CLAIRTECH_API_IN_SUCCESS";
export const CLAIRTECH_API_IN_FAILURE = "CLAIRTECH_API_IN_FAILURE";
export const CLAIRTECH_API_RESET = "CLAIRTECH_API_IN_FAILURE";
export const CLAIRTECH_API_ADD_TEMPLATE = "CLAIRTECH_API_ADD_TEMPLATE";
export const CLAIRTECH_API_GET_TEMPLATE = "CLAIRTECH_API_GET_TEMPLATE";
export const CLAIRTECH_API_DELETE_TEMPLATE = "CLAIRTECH_API_DELETE_TEMPLATE";

// Files
export const FILE_API_REQUEST = "FILE_API_REQUEST";
export const FILE_API_IN_SUCCESS = "FILE_API_IN_SUCCESS";
export const FILE_API_IN_FAILURE = "FILE_API_IN_FAILURE";
export const FILE_API_RESET = "CLAIRTECH_API_IN_FAILURE";
export const FILE_API_GET_FILE_HISTORY = "FILE_API_GET_FILE_HISTORY";
export const FILE_API_GET_PENDING_FILES = "FILE_API_GET_PENDING_FILES";

//Quotes
export const QUOTE_API_REQUEST = "QUOTE_API_REQUEST";
export const QUOTE_API_IN_SUCCESS = "QUOTE_API_IN_SUCCESS";
export const QUOTE_API_IN_FAILURE = "QUOTE_API_IN_FAILURE";
export const QUOTE_API_RESET = "QUOTE_API_RESET";
export const QUOTE_API_GET_CUSTOMERS = "QUOTE_API_GET_CUSTOMERS";
export const QUOTE_API_DELETE_CUSTOMER = "QUOTE_API_DELETE_CUSTOMER";

//Quotes settings
export const QUOTE_SETTING_GET_SORRY =
  "Sorry, something went wrong getting the quote settings";
export const QUOTE_SETTING_ADD = "This quote setting has been added";
export const QUOTE_SETTING_ADD_SORRY =
  "Sorry, something went wrong adding the quote setting";
export const QUOTE_SETTING_SAVE = "This quote setting has been saved";
export const QUOTE_SETTING_SAVE_SORRY =
  "Sorry, something went wrong saving the quote setting";
export const QUOTE_SETTING_REMOVE = "This quote setting has been removed";
export const QUOTE_SETTING_REMOVE_SORRY =
  "Sorry, something went wrong removing this quote setting";

// Quote report out
export const QUOTE_REPORT_OUT_GET_SORRY =
  "sorry, something went wrong getting the quotes data";

// Quote catalogs
export const QUOTE_REPORT_FIELD_GET_SORRY =
  "sorry, something went wrong loading the fields catalog";

//KzooQuotes
export const KZOOQUOTE_API_REQUEST = "KZOOQUOTE_API_REQUEST";
export const KZOOQUOTE_API_IN_SUCCESS = "KZOOQUOTE_API_IN_SUCCESS";
export const KZOOQUOTE_API_IN_FAILURE = "KZOOQUOTE_API_IN_FAILURE";
export const KZOOQUOTE_API_RESET = "KZOOQUOTE_API_RESET";
export const KZOOQUOTE_GET_QUOTE = "KZOOQUOTE_GET_QUOTE";
export const KZOOQUOTE_ADD_QUOTE = "KZOOQUOTE_ADD_QUOTE";
export const KZOOQUOTE_GET_CALCULATED_SORRY =
  "Sorry, something went wrong getting calculated data from quote";
export const KZOOQUOTE_SAVE_SUCCESS = "The quote has been saved";
export const KZOOQUOTE_SAVE_SORRY =
  "Sorry, something went wrong saving the quote";
export const KZOOQUOTE_CONVERT_TO_SORRY =
  "Sorry, something went wrong converting the quote to an order";

export const KZOOQUOTE_SVG_GET_SORRY =
  "Sorry, something went wrong getting this svg";

export const KZOOQUOTE__BATTLESHIP_SVG_GET_SORRY =
  "Sorry, something went wrong getting this info";
export const KZOOQUOTE_FLEX_DIE_QUOTE_SAVE_SUCCESS =
  "The flex die quote has been saved";
export const KZOOQUOTE_FLEX_DIE_QUOTE_SAVE_SORRY =
  "Sorry, something went wrong saving the flex die quote";

//KzooCustomer
export const KZOOCUSTOMER_API_REQUEST = "KZOOCUSTOMER_API_REQUEST";
export const KZOOCUSTOMER_API_IN_SUCCESS = "KZOOCUSTOMER_API_IN_SUCCESS";
export const KZOOCUSTOMER_API_IN_FAILURE = "KZOOCUSTOMER_API_IN_FAILURE";
export const KZOOCUSTOMER_API_RESET = "KZOOCUSTOMER_API_RESET";
export const KZOOCUSTOMER_GET = "KZOOCUSTOMER_GET";
export const KZOOCUSTOMER_DELETE = "KZOOCUSTOMER_DELETE";

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_GET_CURRENT_AUTHENTICATED_USER =
  "AUTH_GET_CURRENT_AUTHENTICATED_USER";

// Cart
export const CART_RESET = "CART_RESET";
export const CART_ADD = "CART_ADD";
export const CART_SAVED = "The parts catalog cart was processed";
export const CART_SAVED_SORRY =
  "Sorry, there was an error trying to process the parts catalog cart";
export const CART_ITEM_REMOVED = "The item has been removed from the cart";
export const CART_ITEM_REMOVED_SORRRY =
  "Sorry, something went wrong removing the item from the cart";
export const CART_ITEM_ADDED = "The item has been added to the cart";
export const CART_ITEM_ADDED_SORRY =
  "Sorry, something went wrong adding the item to the cart";
export const PROCESS_CART_NOTIFICATION = "The notification was sent";
export const PROCESS_CART_NOTIFICATION_SORRY =
  "Sorry, there was an error trying to send the notification";

// Customer Orders
export const CUSTOMER_ORDERS_GET_SORRY =
  "Sorry, something went wrong getting the orders";
export const CUSTOMER_ORDER_DETAILS_GET_SORRY =
  "Sorry, something went wrong getting the order details";
export const CUSTOMER_ORDERS_CHANGE_STATUS = "The status has been changed";
export const CUSTOMER_ORDERS_CHANGE_STATUS_SORRY =
  "Sorry, something went wrong changing the status";

// ExcelInfo
export const EXCEL_INFO_SET = "EXCEL_INFO_SET";
export const EXCEL_INFO_RESET = "EXCEL_INFO_RESET";
export const EXCEL_INFO_MODAL_OPEN_SET = "EXCEL_INFO_MODAL_OPEN_SET";
export const EXCEL_INFO_MODAL_CURRENT_TAB_SET =
  "EXCEL_INFO_MODAL_CURRENT_TAB_SET";
export const EXCEL_INFO_MODAL_SELECTED_SET = "EXCEL_INFO_MODAL_SELECTED_SET";
export const EXCEL_INFO_MODAL_CURRENT_SELECTED_SET =
  "EXCEL_INFO_MODAL_CURRENT_SELECTED_SET";
export const EXCEL_INFO_MODAL_SELECTED_SHOW_INFO_SET =
  "EXCEL_INFO_MODAL_SELECTED_SHOW_INFO_SET";

//Holidays
export const QUOTE_HOLIDAYS_API_GET = "QUOTE_HOLIDAYS_API_GET";
export const QUOTE_HOLIDAYS_API_REQUEST = "QUOTE_HOLIDAYS_API_REQUEST";
export const QUOTE_HOLIDAYS_API_IN_SUCCESS = "QUOTE_HOLIDAYS_API_IN_SUCCESS";
export const QUOTE_HOLIDAYS_API_IN_FAILURE = "QUOTE_HOLIDAYS_API_IN_FAILURE";
export const QUOTE_HOLIDAYS_API_RESET = "QUOTE_HOLIDAYS_API_RESET";
export const QUOTE_HOLIDAYS_GET_SORRY =
  "Sorry, something went wrong getting this holiday";
export const QUOTE_HOLIDAYS_REMOVE_SORRY =
  "Sorry, something went wrong removing this holiday";
export const QUOTE_HOLIDAYS_ADD_SORRY =
  "Sorry, there was an error trying to create this holiday";
export const QUOTE_HOLIDAYS_UPDATE_SORRY =
  "Sorry, there was an error trying to update this holiday.";
export const QUOTE_HOLIDAYS_SAVED = "This holiday has been saved";
export const QUOTE_HOLIDAYS_DELETED = "This holiday has been removed";

//Cost Inputs
export const QUOTE_COST_INPUTS_API_GET = "QUOTE_COST_INPUTS_API_GET";
export const QUOTE_COST_INPUTS_API_REQUEST = "QUOTE_COST_INPUTS_API_REQUEST";
export const QUOTE_COST_INPUTS_API_IN_SUCCESS =
  "QUOTE_COST_INPUTS_API_IN_SUCCESS";
export const QUOTE_COST_INPUTS_API_IN_FAILURE =
  "QUOTE_COST_INPUTS_API_IN_FAILURE";
export const QUOTE_COST_INPUTS_API_RESET = "QUOTE_COST_INPUTS_API_RESET";
export const QUOTE_COST_INPUTS_GET_SORRY =
  "Sorry, something went wrong getting the cost inputs";
export const QUOTE_COST_INPUTS_REMOVE_SORRY =
  "Sorry, something went wrong removing this cost inputs";
export const QUOTE_COST_INPUTS_ADD_SORRY =
  "Sorry, there was an error trying to create the cost inputs";
export const QUOTE_COST_INPUTS_UPDATE_SORRY =
  "Sorry, there was an error trying to update the cost inputs.";
export const QUOTE_COST_INPUTS_SAVED = "This cost inputs has been saved";
export const QUOTE_COST_INPUTS_DELETED = "This cost inputs has been removed";

//Assessmetns
export const ASSESSMENT_API_GET = "ASSESSMENT_API_GET";
export const ASSESSMENT_API_REQUEST = "ASSESSMENT_API_REQUEST";
export const ASSESSMENT_API_IN_SUCCESS = "ASSESSMENT_API_IN_SUCCESS";
export const ASSESSMENT_API_IN_FAILURE = "ASSESSMENT_API_IN_FAILURE";
export const ASSESSMENT_API_RESET = "ASSESSMENT_API_RESET";
export const ASSESSMENT_GET_SORRY =
  "Sorry, something went wrong getting this assessment";
export const ASSESSMENT_REMOVE_SORRY =
  "Sorry, something went wrong removing this assessment";
export const ASSESSMENT_ADD_SORRY =
  "Sorry, there was an error trying to create this assessment";
export const ASSESSMENT_UPDATE_SORRY =
  "Sorry, there was an error trying to update this assessment";
export const ASSESSMENT_SAVED = "This assessment has been saved";
export const ASSESSMENT_DELETED = "This assessment has been removed";

//Classifications
export const CLASSIFICATION_API_GET = "CLASSIFICATIONS_API_GET";
export const CLASSIFICATION_API_REQUEST = "CLASSIFICATIONS_API_REQUEST";
export const CLASSIFICATION_API_IN_SUCCESS = "CLASSIFICATIONS_API_IN_SUCCESS";
export const CLASSIFICATION_API_IN_FAILURE = "CLASSIFICATIONS_API_IN_FAILURE";
export const CLASSIFICATION_API_RESET = "CLASSIFICATIONS_API_RESET";
export const CLASSIFICATION_GET_SORRY =
  "Sorry, something went wrong getting this classification";
export const CLASSIFICATION_REMOVE_SORRY =
  "Sorry, something went wrong removing this classification";
export const CLASSIFICATION_ADD_SORRY =
  "Sorry, there was an error trying to create this classification";
export const CLASSIFICATION_UPDATE_SORRY =
  "Sorry, there was an error trying to update this classification";
export const CLASSIFICATION_SAVED = "This classification has been saved";
export const CLASSIFICATION_DELETED = "This classification has been removed";

//Departments
export const DEPARTMENT_API_GET = "DEPARTMENTS_API_GET";
export const DEPARTMENT_API_REQUEST = "DEPARTMENTS_API_REQUEST";
export const DEPARTMENT_API_IN_SUCCESS = "DEPARTMENTS_API_IN_SUCCESS";
export const DEPARTMENT_API_IN_FAILURE = "DEPARTMENTS_API_IN_FAILURE";
export const DEPARTMENT_API_RESET = "DEPARTMENTS_API_RESET";
export const DEPARTMENT_GET_SORRY =
  "Sorry, something went wrong getting this department";
export const DEPARTMENT_REMOVE_SORRY =
  "Sorry, something went wrong removing this department";
export const DEPARTMENT_ADD_SORRY =
  "Sorry, there was an error trying to create this department";
export const DEPARTMENT_UPDATE_SORRY =
  "Sorry, there was an error trying to update this department";
export const DEPARTMENT_SAVED = "This department has been saved";
export const DEPARTMENT_DELETED = "This department has been removed";

//EmployeeAssessment
export const EMPLOYEEASSESSMENT_API_GET = "EMPLOYEEASSESSMENTS_API_GET";
export const EMPLOYEEASSESSMENT_API_REQUEST = "EMPLOYEEASSESSMENTS_API_REQUEST";
export const EMPLOYEEASSESSMENT_API_IN_SUCCESS =
  "EMPLOYEEASSESSMENTS_API_IN_SUCCESS";
export const EMPLOYEEASSESSMENT_API_IN_FAILURE =
  "EMPLOYEEASSESSMENTS_API_IN_FAILURE";
export const EMPLOYEEASSESSMENT_API_RESET = "EMPLOYEEASSESSMENTS_API_RESET";
export const EMPLOYEEASSESSMENT_GET_SORRY =
  "Sorry, something went wrong getting this employee assessment";
export const EMPLOYEEASSESSMENT_REMOVE_SORRY =
  "Sorry, something went wrong removing this employee assessment";
export const EMPLOYEEASSESSMENT_ADD_SORRY =
  "Sorry, there was an error trying to create this employee assessment";
export const EMPLOYEEASSESSMENT_UPDATE_SORRY =
  "Sorry, there was an error trying to update this employee assessment";
export const EMPLOYEEASSESSMENT_SAVED =
  "This employee assessment has been saved";
export const EMPLOYEEASSESSMENT_DELETED =
  "This employee assessment has been removed";

//NineBoxReport
export const NINEBOXREPORT_API_GET = "NINEBOXREPORTS_API_GET";
export const NINEBOXREPORT_API_REQUEST = "NINEBOXREPORTS_API_REQUEST";
export const NINEBOXREPORT_API_IN_SUCCESS = "NINEBOXREPORTS_API_IN_SUCCESS";
export const NINEBOXREPORT_API_IN_FAILURE = "NINEBOXREPORTS_API_IN_FAILURE";
export const NINEBOXREPORT_API_RESET = "NINEBOXREPORTS_API_RESET";
export const NINEBOXREPORT_GET_SORRY =
  "Sorry, something went wrong getting this object";
export const NINEBOXREPORT_REMOVE_SORRY =
  "Sorry, something went wrong removing this object";
export const NINEBOXREPORT_ADD_SORRY =
  "Sorry, there was an error trying to create this object";
export const NINEBOXREPORT_UPDATE_SORRY =
  "Sorry, there was an error trying to update this object";
export const NINEBOXREPORT_SAVED = "This object has been saved";
export const NINEBOXREPORT_DELETED = "This object has been removed";

//Position
export const POSITION_API_GET = "POSITIONS_API_GET";
export const POSITION_API_REQUEST = "POSITIONS_API_REQUEST";
export const POSITION_API_IN_SUCCESS = "POSITIONS_API_IN_SUCCESS";
export const POSITION_API_IN_FAILURE = "POSITIONS_API_IN_FAILURE";
export const POSITION_API_RESET = "POSITIONS_API_RESET";
export const POSITION_GET_SORRY =
  "Sorry, something went wrong getting this position";
export const POSITION_REMOVE_SORRY =
  "Sorry, something went wrong removing this position";
export const POSITION_ADD_SORRY =
  "Sorry, there was an error trying to create this position";
export const POSITION_UPDATE_SORRY =
  "Sorry, there was an error trying to update this position";
export const POSITION_SAVED = "This position has been saved";
export const POSITION_DELETED = "This position has been removed";

//TrialLogsCustomer
export const TRIALS_LOG_CUSTOMER_API_GET = "TRIALS_LOG_CUSTOMER_API_GET";
export const TRIALS_LOG_CUSTOMER_API_REQUEST =
  "TRIALS_LOG_CUSTOMER_API_REQUEST";
export const TRIALS_LOG_CUSTOMER_API_IN_SUCCESS =
  "TRIALS_LOG_CUSTOMER_API_IN_SUCCESS";
export const TRIALS_LOG_CUSTOMER_API_IN_FAILURE =
  "TRIALS_LOG_CUSTOMER_API_IN_FAILURE";
export const TRIALS_LOG_CUSTOMER_API_RESET = "TRIALS_LOG_CUSTOMER_API_RESET";
export const TRIALS_LOG_CUSTOMER_GET_SORRY =
  "Sorry, something went wrong getting the customer";
export const TRIALS_LOG_CUSTOMER_REMOVE_SORRY =
  "Sorry, something went wrong removing the customer";
export const TRIALS_LOG_CUSTOMER_ADD_SORRY =
  "Sorry, there was an error trying to create the customer";
export const TRIALS_LOG_CUSTOMER_UPDATE_SORRY =
  "Sorry, there was an error trying to update the customer";
export const TRIALS_LOG_CUSTOMER_SAVED = "The customer has been saved";
export const TRIALS_LOG_CUSTOMER_DELETED = "The customer has been removed";

//TrialLogs
export const TRIALS_LOG_API_GET = "TRIALS_LOG_API_GET";
export const TRIALS_LOG_API_REQUEST = "TRIALS_LOG_API_REQUEST";
export const TRIALS_LOG_API_IN_SUCCESS = "TRIALS_LOG_API_IN_SUCCESS";
export const TRIALS_LOG_API_IN_FAILURE = "TRIALS_LOG_API_IN_FAILURE";
export const TRIALS_LOG_API_RESET = "TRIALS_LOG_API_RESET";
export const TRIALS_LOG_GET_SORRY =
  "Sorry, something went wrong getting this data";
export const TRIALS_LOG_REMOVE_SORRY =
  "Sorry, something went wrong removing this data";
export const TRIALS_LOG_ADD_SORRY =
  "Sorry, there was an error trying to create this data";
export const TRIALS_LOG_UPDATE_SORRY =
  "Sorry, there was an error trying to update this data";
export const TRIALS_LOG_SAVED = "This data has been saved";
export const TRIALS_LOG_DELETED = "This data has been removed";

//Employees
export const EMPLOYEE_API_GET = "EMPLOYEE_API_GET";
export const EMPLOYEE_API_REQUEST = "EMPLOYEE_API_REQUEST";
export const EMPLOYEE_API_IN_SUCCESS = "EMPLOYEE_API_IN_SUCCESS";
export const EMPLOYEE_API_IN_FAILURE = "EMPLOYEE_API_IN_FAILURE";
export const EMPLOYEE_API_RESET = "EMPLOYEE_API_RESET";
export const EMPLOYEE_GET_SORRY =
  "Sorry, something went wrong getting this employee";
export const EMPLOYEE_REMOVE_SORRY =
  "Sorry, something went wrong removing this employee";
export const EMPLOYEE_ADD_SORRY =
  "Sorry, there was an error trying to create this employee";
export const EMPLOYEE_UPDATE_SORRY =
  "Sorry, there was an error trying to update this employee";
export const EMPLOYEE_SAVED = "The employee has been saved";
export const EMPLOYEE_DELETE = "The employee has been removed";

export const TGE_CATEGORY_SALES_GET_SORRY =
  "sorry, something went wrong getting the sales data";

//User Management
export const USERMANAGEMENT_GET_SORRY =
  "Sorry, something went wrong getting users information";
export const USERMANAGEMENT_SAVED = "This user permission has been saved";
export const USERMANAGEMENT_CHANGE_PERMISSION_SORRY =
  "Sorry, something went wrong changing this user permission";
export const USERMANAGEMENT_APPS_GET_SORRY =
  "Sorry, something went wrong getting apps information";

//Rotary Die
export const ROTARYDIE_DATA_GET_SORRY =
  "Sorry, something went wrong getting die information";
export const ROTARYDIE_DETAIL_DATA_GET_SORRY =
  "Sorry, something went wrong getting die detail information";

export const ROTARYDIE_DEVICE_DATA_GET_SORRY =
  "Sorry, something went wrong getting the devices";

export const ROTARYDIE_DIE_DETAIL_GET_SORRY =
  "Sorry, something went wrong getting the dies";
export const ROTARYDIE_DIE_DETAIL_ADD = "This die has been added";
export const ROTARYDIE_DIE_DETAIL_ADD_SORRY =
  "Sorry, something went wrong adding the die";
export const ROTARYDIE_DIE_DETAIL_SAVE = "This die has been saved";
export const ROTARYDIE_DIE_DETAIL_SAVE_SORRY =
  "Sorry, something went wrong saving the die";
export const ROTARYDIE_DIE_DETAIL_REMOVE = "This die has been removed";
export const ROTARYDIE_DIE_DETAIL_REMOVE_SORRY =
  "Sorry, something went wrong removing this die";

export const ROTARYDIE_DIE_DETAIL_CUSTOMER_GET_SORRY =
  "Sorry, something went wrong getting the customers";

export const ROTARYDIE_CUSTOMER_DATA_GET_SORRY =
  "Sorry, something went wrong getting the customers";
export const ROTARYDIE_CUSTOMER_SAVE = "This customer has been saved";
export const ROTARYDIE_CUSTOMER_SAVE_SORRY =
  "Sorry, something went wrong saving the customer";
export const ROTARYDIE_CUSTOMER_REMOVE = "This customer has been removed";
export const ROTARYDIE_CUSTOMER_REMOVE_SORRY =
  "Sorry, something went wrong removing this customer";

export const ROTARYDIE_CYLINDER_DATA_GET_SORRY =
  "Sorry, something went wrong getting the cylinder data";
export const ROTARYDIE_CYLINDER_SAVE = "This cylinder has been saved";
export const ROTARYDIE_CYLINDER_SAVE_SORRY =
  "Sorry, something went wrong saving the cylinder";
export const ROTARYDIE_CYLINDER_REMOVE = "This cylinder has been removed";
export const ROTARYDIE_CYLINDER_REMOVE_SORRY =
  "Sorry, something went wrong removing this cylinder";

export const ROTARYDIE_DEVICE_DIE_GET_SORRY =
  "Sorry, something went wrong getting the die's devices";
export const ROTARYDIE_DEVICE_DIE_SAVE = "This device has been added";
export const ROTARYDIE_DEVICE_DIE_SAVE_SORRY =
  "Sorry, something went wrong adding the device";
export const ROTARYDIE_DEVICE_DIE_REMOVE = "This device has been removed";
export const ROTARYDIE_DEVICE_DIE_REMOVE_SORRY =
  "Sorry, something went wrong removing this device";

export const ROTARYDIE_DETAIL_ACCEL_DATA_GET_SORRY =
  "Sorry, something went wrong getting die detail accel information";

// Die Lines
export const ROTARYDIE_LINE_GET_SORRY =
  "Sorry, something went wrong getting the lines";
export const ROTARYDIE_LINE_ADD = "This line has been added";
export const ROTARYDIE_LINE_ADD_SORRY =
  "Sorry, something went wrong adding the line";
export const ROTARYDIE_LINE_SAVE = "This line has been saved";
export const ROTARYDIE_LINE_SAVE_SORRY =
  "Sorry, something went wrong saving the line";
export const ROTARYDIE_LINE_REMOVE = "This line has been removed";
export const ROTARYDIE_LINE_REMOVE_SORRY =
  "Sorry, something went wrong removing this line";

// Die Modules
export const ROTARYDIE_MODULE_GET_SORRY =
  "Sorry, something went wrong getting the modules";
export const ROTARYDIE_MODULE_ADD = "This module has been added";
export const ROTARYDIE_MODULE_ADD_SORRY =
  "Sorry, something went wrong adding the module";
export const ROTARYDIE_MODULE_SAVE = "This module has been saved";
export const ROTARYDIE_MODULE_SAVE_SORRY =
  "Sorry, something went wrong saving the module";
export const ROTARYDIE_MODULE_REMOVE = "This module has been removed";
export const ROTARYDIE_MODULE_REMOVE_SORRY =
  "Sorry, something went wrong removing this module";

// Die Devices
export const ROTARYDIE_DEVICE_GET_SORRY =
  "Sorry, something went wrong getting the devices";
export const ROTARYDIE_DEVICE_ADD = "This device has been added";
export const ROTARYDIE_DEVICE_ADD_SORRY =
  "Sorry, something went wrong adding the device";
export const ROTARYDIE_DEVICE_SAVE = "This device has been saved";
export const ROTARYDIE_DEVICE_SAVE_SORRY =
  "Sorry, something went wrong saving the device";
export const ROTARYDIE_DEVICE_REMOVE = "This device has been removed";
export const ROTARYDIE_DEVICE_REMOVE_SORRY =
  "Sorry, something went wrong removing this device";

// Die Modules
export const ROTARYDIE_DIE_MODULE_GET_SORRY =
  "Sorry, something went wrong getting the dies and modules";
export const ROTARYDIE_DIE_MODULE_ADD = "This die has been added";
export const ROTARYDIE_DIE_MODULE_ADD_SORRY =
  "Sorry, something went wrong adding the die";
export const ROTARYDIE_DIE_MODULE_REMOVE = "This die has been removed";
export const ROTARYDIE_DIE_MODULE_REMOVE_SORRY =
  "Sorry, something went wrong removing this die";

//Rochefort Report
export const ROCHEFORT_REPORT_DATA_GET_SORRY =
  "Sorry, something went wrong getting die information";

// Common
export const GENERIC_API_SUCCESS_MESSAGE = "Action completed successfully";
export const GENERIC_API_ERROR_MESSAGE = "Sorry, something went wrong";

//Efficiency Report
export const EFFICIENCY_REPORT_SETTING_GET_SORRY =
  "Sorry, something went wrong getting the settings";
export const EFFICIENCY_REPORT_CATALOG_GET_SORRY =
  "Sorry, something went wrong loading the efficiency catalogs";
export const EFFICIENCY_REPORT_SETTING_ADD =
  "This efficiency report setting has been added";
export const EFFICIENCY_REPORT_SETTING_ADD_SORRY =
  "Sorry, something went wrong adding the setting";
export const EFFICIENCY_REPORT_SETTING_SAVE =
  "This efficiency report setting has been saved";
export const EFFICIENCY_REPORT_SETTING_SAVE_SORRY =
  "Sorry, something went wrong saving the setting";
export const EFFICIENCY_REPORT_SETTING_REMOVE =
  "This efficiency report setting has been removed";
export const EFFICIENCY_REPORT_SETTING_REMOVE_SORRY =
  "Sorry, something went wrong removing the setting";
export const EFFICIENCY_REPORT_FIELD_GET_SORRY =
  "sorry, something went wrong loading the fields catalog";

export const SOW_DATA_GET_SORRY =
  "Sorry, something went wrong getting the customers";
export const SOW_CUSTOMER_SAVE = "This customer has been saved";
export const SOW_CUSTOMER_SAVE_SORRY =
  "Sorry, something went wrong saving the customer";
export const SOW_CUSTOMER_REMOVE = "This customer has been removed";
export const SOW_CUSTOMER_REMOVE_SORRY =
  "Sorry, something went wrong removing this customer";

export const SOW_INVENTORY_GET_SORRY =
  "Sorry, something went wrong getting the inventory";
export const SOW_INVENTORY_SAVE = "This part has been saved";
export const SOW_INVENTORY_SAVE_SORRY =
  "Sorry, something went wrong saving this part";
export const SOW_INVENTORY_REMOVE = "This part has been removed";
export const SOW_INVENTORY_REMOVE_SORRY =
  "Sorry, something went wrong removing this part";

export const COUNTRIES_DATA_GET_SORRY =
  "Sorry, something went wrong getting the countries";

export const DEPARTMENTS_GET_DATA_SORRY =
  "Sorry, something went wrong getting the departments";

export const PRICE_COMPLEMENTS_DATA_GET_SORRY =
  "Sorry, something went wrong getting the price complement";
export const PRICE_COMPLEMENTS_CUSTOMER_SAVE =
  "This price complement has been saved";
export const PRICE_COMPLEMENTS_CUSTOMER_SAVE_SORRY =
  "Sorry, something went wrong saving the price complement";
export const PRICE_COMPLEMENTS_CUSTOMER_REMOVE =
  "This price complement has been removed";
export const PRICE_COMPLEMENTS_CUSTOMER_REMOVE_SORRY =
  "Sorry, something went wrong removing this price complement";

export const PARTS_CATALOG_ITEM_GET_SORRY =
  "Sorry, something went wrong getting the parts catalog";
export const PARTS_CATALOG_ITEM_GET_SAVE = "This item has been saved";
export const PARTS_CATALOG_ITEM_GET_SAVE_SORRY =
  "Sorry, something went wrong saving this item";
export const PARTS_CATALOG_ITEM_REMOVE = "This item has been removed";
export const PARTS_CATALOG_ITEM_REMOVE_SORRY =
  "Sorry, something went wrong removing this item";

export const CUSTOMER_PREFERENCE_ITEM_GET_SORRY =
  "Sorry, something went wrong getting the customer preference";
export const CUSTOMER_PREFERENCE_ITEM_GET_SAVE =
  "The customer preference has been saved";
export const CUSTOMER_PREFERENCE_ITEM_GET_SAVE_SORRY =
  "Sorry, something went wrong saving the customer preference";
export const CUSTOMER_PREFERENCE_ITEM_REMOVE =
  "This customer preference has been removed";
export const CUSTOMER_PREFERENCE_ITEM_REMOVE_SORRY =
  "Sorry, something went wrong removing the customer preference";

export const BERNAL_RETOOL_ITEM_GET_SORRY =
  "Sorry, something went wrong getting this record";
export const BERNAL_RETOOL_ITEM_GET_SAVE = "This item has been saved";
export const BERNAL_RETOOL_ITEM_GET_SAVE_SORRY =
  "Sorry, something went wrong saving this item";
export const BERNAL_RETOOL_ITEM_REMOVE = "This item has been removed";
export const BERNAL_RETOOL_ITEM_REMOVE_SORRY =
  "Sorry, something went wrong removing this item";
export const BERNAL_RETOOL_REQUIRED_CHECKBOX_SORRY =
  "Must select at least one of the offered service";
export const BERNAL_RETOOL_REQUIRED_IMAGE =
  "Must upload an image for Weld Repair Evaluation or Warranty Evaluation";

export const MIDWAY_RETOOL_ITEM_GET_SORRY =
  "Sorry, something went wrong getting this record";
export const MIDWAY_RETOOL_ITEM_GET_SAVE = "This item has been saved";
export const MIDWAY_RETOOL_ITEM_GET_SAVE_SORRY =
  "Sorry, something went wrong saving this item";
export const MIDWAY_RETOOL_ITEM_REMOVE = "This item has been removed";
export const MIDWAY_RETOOL_ITEM_REMOVE_SORRY =
  "Sorry, something went wrong removing this item";
