import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { getAuthenticatedUser, signOut } from "../../redux/actions/authActions";

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ appId, children }) => {
  const [isAllowed, setIsAllowed] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.authReducer);

  const handleSignOut = useCallback(async () => {
    await dispatch(signOut());
    history.push("/auth/sign-in");
  }, [dispatch, history]);

  useEffect(() => {
    if (!auth.user) {
      dispatch(getAuthenticatedUser())
        .then((response) => {
          const { user } = response;

          if (user) {
            if (appId && user.appPermissionList) {
              setIsAllowed(user.appPermissionList.includes(appId));
            }
          }
        })
        .catch(() => {
          handleSignOut();
        });
    } else {
      if (appId && auth.user.appPermissionList) {
        setIsAllowed(auth.user.appPermissionList.includes(appId));
      }
    }
  }, [dispatch, auth.user, appId, handleSignOut]);

  if (!isAllowed) {
    return <Redirect to="/auth/403" />;
  }

  return children;
};

export default AuthGuard;
