import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Fab, TextField, Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";
import { AddCircleOutline as AddIcon } from "@material-ui/icons";
import { RemoveCircleOutline as RemoveIcon } from "@material-ui/icons";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  addItemToCart,
  updateQuantity,
} from "../../../redux/actions/cartActions";
import * as type from "constants/index";
import { useSnackbar } from "notistack";

const AddButton = styled(Fab)`
  height: 40px;
  width: 40px;
`;

const TexFieldQuantity = styled(TextField)`
  width: 70px;
`;

const ShoppingCartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AddIconButton = styled(AddIcon)`
  svg {
    width: 22px;
    height: 22px;
  }

  :hover {
    cursor: pointer;
  }
`;

const RemoveIconButton = styled(RemoveIcon)`
  svg {
    width: 22px;
    height: 22px;
  }

  :hover {
    cursor: pointer;
  }
`;

const ShoppingItem = ({
  id,
  name,
  price,
  pic,
  quantity = 1,
  enableAdd = false,
  handleUpdateStorage = false,
  control,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const { enqueueSnackbar } = useSnackbar();

  const [formItemData, setFormItemData] = useState({
    user: auth?.user?.name,
    item: id,
    name,
    pic,
    price,
    quantity: quantity,
  });

  useEffect(() => {
    if (handleUpdateStorage) {
      dispatch(updateQuantity(formItemData));
    }
  }, [dispatch, formItemData, handleUpdateStorage]);

  const handleUpdateCart = () => {
    const processFormItemData = { ...formItemData, ...control._formValues };
    try {
      dispatch(addItemToCart(processFormItemData));
      handleCloseDialog();
      enqueueSnackbar(type.CART_ITEM_ADDED, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } catch {
      enqueueSnackbar(type.CART_ITEM_ADDED_SORRY, {
        variant: "error",
      });
    }
  };

  const handleQuantityChange = (e) => {
    const value = Number(e.target.value) || 1;
    const validatedQuantity = value <= 0 ? 1 : value;
    setFormItemData({ ...formItemData, quantity: Number(validatedQuantity) });
  };

  const handleIncreaseQuantity = () => {
    setFormItemData({ ...formItemData, quantity: formItemData.quantity + 1 });
  };

  const handleDecreaseQuantity = () => {
    const validatedQuantity =
      formItemData.quantity - 1 === 0 ? 1 : formItemData.quantity - 1;
    setFormItemData({ ...formItemData, quantity: validatedQuantity });
  };

  return (
    <>
      <ShoppingCartContainer>
        {enableAdd && (
          <Tooltip title="Add this product to cart">
            <AddButton color="primary" onClick={handleUpdateCart}>
              <ShoppingCartIcon />
            </AddButton>
          </Tooltip>
        )}
        <Typography component="h2" variant="h3" color="textPrimary">
          ${price?.toLocaleString("en-US")}
        </Typography>
        <QuantityContainer>
          <RemoveIconButton onClick={handleDecreaseQuantity} color="primary" />
          <TexFieldQuantity
            type="text"
            name="quantity"
            label="Quantity"
            variant="outlined"
            size="small"
            value={formItemData.quantity}
            onChange={handleQuantityChange}
          />
          <AddIconButton onClick={handleIncreaseQuantity} color="primary" />
        </QuantityContainer>
      </ShoppingCartContainer>
    </>
  );
};

export default ShoppingItem;
